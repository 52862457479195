import "./HyFooter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { HashLink as Link } from "react-router-hash-link";
import { AppPaths } from "../../AppPaths";
import locksmithEnglish from "../../assets/svg/logo_or_brand/Norske-låsesmeder-Samarbeid_u_ramme_engelsk[1111].svg";
import locksmithNorwegian from "../../assets/svg/logo_or_brand/Norske-låsesmeder-Samarbeid_m_ramme[1110].svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { allowedLanguages } from "../../utilities/Types";
import logo from "../../assets/svg/logo_or_brand/logo.svg";
import logoTextLight from "../../assets/images/div/brand.png";
// import { Link as remixLink } from "@remix-run/react";
// import { Link } from "react-router-dom";

export default function HyFooter() {
  const { t } = useTranslation();

  return (
    <div className="HyFooter-container">
      <div className="HyFooter-container-top">
        <div
          style={{
            display: "flex",
            padding: "0.5rem 0.75rem",
          }}
        >
          <img
            src={logo}
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              marginRight: "3px",
            }}
            alt=""
          />
          <img src={logoTextLight} alt="Hygotech" />
        </div>
      </div>
      <div className="HyFooter-container-middle">
        <div className="HyFooter-link-container">
          <h3>{t("navbar-footer.products.title")}</h3>
          {/* <Link as={remixLink} className="footer-link" to="/products"> */}
          <Link className="footer-link" to="/products">
            {t("navbar-footer.products.i2OpenCeiling")}
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.PRODUCTS,
              hash: "#i2OpenWall",
            }}
          >
            {t("navbar-footer.products.i2OpenWall")}
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.PRODUCTS,
              hash: "#app",
            }}
          >
            {t("navbar-footer.products.app")}
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.PRODUCTS,
              hash: "#accessories",
            }}
          >
            {t("navbar-footer.products.accessories")}
          </Link>
        </div>
        <div className="HyFooter-link-container">
          <h3> {t("navbar-footer.environments.title")}</h3>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.ENVIRONMENTS,
              hash: "environment-medical",
            }}
          >
            <p className="header-link">
              {t("navbar-footer.environments.title")}
            </p>
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.ENVIRONMENTS,
              hash: "environment-office",
            }}
          >
            <p className="header-link">
              {t("navbar-footer.environments.office")}
            </p>
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.ENVIRONMENTS,
              hash: "environment-school",
            }}
          >
            <p className="header-link">
              {t("navbar-footer.environments.school")}
            </p>
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.ENVIRONMENTS,
              hash: "environment-hotel",
            }}
          >
            <p className="header-link">
              {t("navbar-footer.environments.hotel")}
            </p>
          </Link>
          <Link
            className="footer-link"
            to={{
              pathname: AppPaths.ENVIRONMENTS,
              hash: "environment-parking",
            }}
          >
            <p className="header-link">
              {t("navbar-footer.environments.parking")}
            </p>
          </Link>
        </div>
        <div className="HyFooter-link-container">
          <h3>{t("navbar-footer.contact-us")}</h3>
          <a
            href="mailto:info@hygotech.com"
            className="footer-link"
            style={{ marginBottom: "0" }}
          >
            {t("navbar-footer.email")}: info@hygotech.com
          </a>
          <p style={{ marginBottom: "0" }}>
            {t("navbar-footer.phone")}: 452 62 000
          </p>
          <p style={{ marginBottom: "0" }}>Org.nr. NO 919 482 788 MVA </p>
          <div style={{ display: "flex" }}>
            <p style={{ paddingRight: "0.5rem" }}>
              {t("navbar-footer.social-media")}:
            </p>
            <a
              href="https://no.linkedin.com/company/hygotech"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
              style={{ margin: "0 5px" }}
            >
              <FontAwesomeIcon
                icon={faLinkedin as IconDefinition}
                className="footer-link"
                size="lg"
              />
            </a>
            <a
              href="https://www.facebook.com/Hygotech"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
              style={{ margin: "0 5px" }}
            >
              <FontAwesomeIcon
                icon={faFacebook as IconDefinition}
                className="footer-link"
                size="lg"
              />
            </a>
            <a
              href="https://www.instagram.com/hygotech/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
              style={{ margin: "0 5px" }}
            >
              <FontAwesomeIcon
                icon={faInstagram as IconDefinition}
                className="footer-link"
                size="lg"
              />
            </a>
          </div>
        </div>
        <div className="HyFooter-container-middle-locksmith-img">
          <img
            src={
              i18next.language == allowedLanguages.NO
                ? locksmithNorwegian
                : locksmithEnglish
            }
            alt="Image showcasing how we work with the norwegian locksmih association"
          />
        </div>
      </div>
      <div className="HyFooter-container-bottom">
        <p>2017-2023 © Hygotech AS</p>
      </div>
    </div>
  );
}
