import "./OrderPage.scss";

import PageTemplate from "../pagetemplate/PageTemplate";
import { useEffect } from "react";
import MultiStepOrderForm from "../../components/order/MultistepOrderForm";
import { useTranslation } from "react-i18next";

export default function OrderPage() {
  const { t } = useTranslation();

  useEffect(() => {
    window.dataLayer.push({ eventProps: undefined });
    window.dataLayer.push({ event: "beginOrder" });
  }, []);

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="order-page-container">
        <h2 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
          {t("order-page.title")}
        </h2>
        <p>{t("order-page.large-orders-1")}</p>
        <p>{t("order-page.large-orders-2")}</p>
        <div id="order-page-content-container">
          <div id="order-page-order-form-container">
            <MultiStepOrderForm />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}
