import HyHeader from "./HyHeader";
import HyFooter from "./HyFooter";
import { PropsWithChildren } from "react";
import { Column } from "trunx";

export interface PageTemplateProps {
  isNavbarTransparentToSolid: boolean;
}

export default function PageTemplate(
  props: PropsWithChildren<PageTemplateProps>
) {
  return (
    <div>
      <HyHeader isNavbarTransparentToSolid={props.isNavbarTransparentToSolid} />
      <Column isFlex isFlexDirectionColumn isFlexGrow1 p0>
        <>{props.children}</>
      </Column>
      <HyFooter />
    </div>
  );
}
