import "./ArgumentCard.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "trunx";
import { AppPaths } from "../../AppPaths";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface ArgumentCardProps {
  title: string;
  text: string;
  imageSrc: string;
  id: number;
}

export default function ArgumentCard({
  title,
  text,
  imageSrc,
  id,
}: ArgumentCardProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div className="unique-selling-point-card">
      <img
        className="unique-selling-point-image"
        src={imageSrc}
        alt="image showcasing the unique selling point"
      />
      <div className="unique-selling-point-content">
        <h3 className="unique-selling-point-title">{title}</h3>
        <p className="unique-selling-point-text">{text} </p>
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
          }}
          isRounded
          isPrimary
          isAlignSelfCenter
          mt2
          onClick={() => {
            window.dataLayer.push({ eventProps: undefined });
            window.dataLayer.push({
              event: "read-more-usp",
              eventProps: {
                usp: id,
              },
            });
            history.push(`${AppPaths.USP_DETAILED_VIEW}?id=${id}`);
          }}
        >
          <p>{t("unique-selling-points.read-more")}</p>
          <FontAwesomeIcon
            style={{ marginLeft: "10px", marginBottom: "-5px" }}
            icon={faChevronRight}
          />{" "}
        </Button>
      </div>
    </div>
  );
}
