import "./ProductsPage.scss";

import PageTemplate from "../pagetemplate/PageTemplate";
import footprint from "../../assets/images/products/footprint.png";
import i2OpenInnfellbar from "../../assets/images/products/i2Open_innfellbar.jpg";
import i2Open from "../../assets/images/products/i2Open.jpg";
import bracket from "../../assets/images/products/bracket.jpg";
import app from "../../assets/images/products/app.jpg";
import lysfilter from "../../assets/images/products/lysfilter.jpg";
import cleaningWhipes from "../../assets/images/products/overflateRens.jpg";
import floorMarkRaiseToOpen from "../../assets/images/products/image_of_raise_to_open_sticker.png";
import { useTranslation } from "react-i18next";
import ProductCard from "../../components/products/ProductCard";

export default function ProductsPage() {
  const { t, i18n } = useTranslation();

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="product-page-container">
        <div className="product-page-products-container">
          <h2>i2Open produkter</h2>
          <div className="products-grid">
            <ProductCard
              title={t("product-page.ceiling.title")}
              text={t("product-page.ceiling.text")}
              imageSrc={i2OpenInnfellbar}
              id={"i2OpenCeling"}
            />

            <ProductCard
              title={t("product-page.wall.title")}
              text={t("product-page.wall.text")}
              imageSrc={i2Open}
              id={"i2OpenWall"}
            />

            <ProductCard
              title={t("product-page.app.title")}
              text={t("product-page.app.text")}
              imageSrc={app}
              id={"app"}
            />
          </div>
        </div>

        <div className="product-page-accessories-container" id="accessories">
          <h2>{t("product-page.accessories.title")}</h2>
          <div className="accessories-grid">
            <ProductCard
              title={t(
                "product-page.accessories.floor-mark-step-to-open.title"
              )}
              text={t("product-page.accessories.floor-mark-step-to-open.text")}
              imageSrc={footprint}
              id={"floor-mark-step-to-open"}
            />
            <ProductCard
              title={t(
                "product-page.accessories.floor-mark-step-to-open-luminecent.title"
              )}
              text={t(
                "product-page.accessories.floor-mark-step-to-open-luminecent.text"
              )}
              imageSrc={footprint}
              id={"floor-mark-step-to-open-luminecent"}
            />
            <ProductCard
              title={t(
                "product-page.accessories.floor-mark-raise-to-open.title"
              )}
              text={t("product-page.accessories.floor-mark-raise-to-open.text")}
              imageSrc={floorMarkRaiseToOpen}
              id={"floor-mark-raise-to-open"}
            />
            <ProductCard
              title={t("product-page.accessories.bracket.title")}
              text={t("product-page.accessories.bracket.text")}
              imageSrc={bracket}
              id={"bracket"}
            />
            <ProductCard
              title={t("product-page.accessories.cleaning-whipes.title")}
              text={t("product-page.accessories.cleaning-whipes.text")}
              imageSrc={cleaningWhipes}
              id={"cleaningWhipes"}
            />

            <ProductCard
              title={t("product-page.accessories.light-filter.title")}
              text={t("product-page.accessories.light-filter.text")}
              imageSrc={lysfilter}
              id={"lightFilter"}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}
