import "./App.scss";

import OrderPage from "./pages/order/OrderPage";
import LogInPage from "./pages/LogInPage";
import ArticlesPage from "./pages/articles/ArticlesPage";
import HyVideoPage from "./pages/HyVideoPage";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { AppPaths } from "./AppPaths";
import { AuthProvider } from "./contexts/AuthContex";
import CreateArticlesPage from "./pages/admin/CreateArticlesPage";
import CreateRefrencePage from "./pages/admin/CreateRefrencePage";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import PrivateRoute from "./components/PrivateRoute";
import CustomerSupportPage from "./pages/customersupport/CustomerSupportPage";
import AboutUsPage from "./pages/aboutus/AboutUsPage";
import RefrencesPage from "./pages/refrences/RefrencesPage";
import HomePage from "./pages/homepage/HomePage";
import DetailedArticleView from "./pages/articles/DetailedArticleView";
import DetailedRefrenceView from "./pages/refrences/DetailedRefrenceView";
import EnvironmentsPage from "./pages/environments/EnvironmentsPage";
import ProductsPage from "./pages/products/ProductsPage";
import UspDetailedViewPage from "./pages/uspDetailedView/UspDetailedViewPage";
import "./i18n";

export default function App() {
  return (
    <Router hashType="slash">
      <AuthProvider>
        <Switch>
          <Route exact path={AppPaths.LOGIN} component={LogInPage} />
          <Route exact path={AppPaths.ORDER_PAGE} component={OrderPage} />
          <Route
            exact
            path={AppPaths.CUSTOMER_SUPPORT}
            component={CustomerSupportPage}
          />
          <Route exact path={AppPaths.ABOUT} component={AboutUsPage} />
          <Route exact path={AppPaths.ARTICLES} component={ArticlesPage} />

          <Route exact path={AppPaths.HYGOTECH_VIDEO} component={HyVideoPage} />
          <Route exact path={AppPaths.REFRENCES} component={RefrencesPage} />
          <Route
            exact
            path={AppPaths.USP_DETAILED_VIEW}
            component={UspDetailedViewPage}
          />

          <Route
            exact
            path={AppPaths.ENVIRONMENTS}
            component={EnvironmentsPage}
          />
          <Route
            exact
            path={AppPaths.DETAILED_ARTICLE_VIEW}
            component={DetailedArticleView}
          />

          <Route
            exact
            path={AppPaths.DETAILED_REFRENCE_VIEW}
            component={DetailedRefrenceView}
          />

          <Route exact path={AppPaths.PRODUCTS} component={ProductsPage} />

          <PrivateRoute
            exact
            path={AppPaths.ADMIN}
            component={AdminDashboardPage}
          />

          <PrivateRoute
            exact
            path={AppPaths.CREATE_REFRENCE}
            component={CreateRefrencePage}
          />
          <PrivateRoute
            exact
            path={AppPaths.CREATE_ARTICLES}
            component={CreateArticlesPage}
          />

          <Route path={AppPaths.HOME} component={HomePage} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}
