import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContex";
import { AppPaths } from "../AppPaths";

export interface PrivateRouteProps {
  component?: React.ComponentType<any>;
  componentRoleMap?: { [key: string]: React.ComponentType<any> };
  exact?: boolean;
  path?: string;
}

export default function PrivateRoute({
  component: Component,
  componentRoleMap,
  ...rest
}: PrivateRouteProps) {
  const { currentUser } = useAuth();
  let renderFunction = (props: any) => (
    <Redirect
      to={{
        pathname: AppPaths.LOGIN,
        state: {
          from: props.location,
        },
      }}
    />
  );

  if (currentUser && Component) {
    renderFunction = (props) => <Component {...props} />;
  }

  return <Route {...rest} render={renderFunction} />;
}
