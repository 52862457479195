import React, { ReactElement, Ref } from "react";
import { Input, Label } from "trunx";

type InputProps = typeof Input.prototype.props;
type LabelProps = typeof Label.prototype.props;

export interface HyInputFieldProps extends InputProps {
  name?: string;
  type?: "text" | "number" | "password" | "email" | "checkbox";
  label?: string;
  labelProps?: LabelProps;
  placeholder?: string;
  register?: any;
}

export default function HyInputField(props: HyInputFieldProps) {
  const {
    register,
    name,
    type,
    label,
    placeholder,
    labelProps,
    ...otherProps
  } = props;
  return (
    <>
      {label && (
        <Label mt2 isAlignSelfFlexStart mb1 {...labelProps}>
          {label}
        </Label>
      )}
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        {...otherProps}
        {...register}
      />
    </>
  );
}
