import "./AvailableProductsTable.scss";

import raise_to_open_sticker from "../../assets/images/products/image_of_raise_to_open_sticker.png";
import step_to_open_sticker from "../../assets/images/products/image_of_step_to_open_sticker.jpg";
import bracket from "../../assets/images/products/bracket.jpg";
import cleaningWhipes from "../../assets/images/products/overflateRens.jpg";
import lightFilter from "../../assets/images/products/lysfilter.jpg";
import { OrderFormStep2Props } from "./MultistepOrderForm";
import { AvailableProductsTableRow } from "./AvailableProductsTable";

export default function AvailableProductsTableAccessories({
  nrOf30001,
  nrOf30002,
  nrOf30003,
  nrOf40001,
  nrOf40002,
  nrOf50001,
  setNrOf30001,
  setNrOf30002,
  setNrOf30003,
  setNrOf40001,
  setNrOf40002,
  setNrOf50001,
}: OrderFormStep2Props) {
  return (
    <div
      className="available-products-table"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "50px repeat(6, 1fr)",
        maxHeight: "700px",
      }}
    >
      <div className="available-products-header">
        <p>Art.nr</p>
        <p>Beskrivelse</p>
        <p>Bilde</p>
        <p>Pris</p>
        <p>Antall</p>
      </div>
      <AvailableProductsTableRow
        srnr={30001}
        text={'"Step to Open" gulvmerke'}
        imagePath={step_to_open_sticker}
        price={160}
        number={nrOf30001}
        setNumber={setNrOf30001}
      />
      <AvailableProductsTableRow
        srnr={30002}
        text={'"Raise to Open" betjeningsmerke'}
        imagePath={raise_to_open_sticker}
        price={160}
        number={nrOf30002}
        setNumber={setNrOf30002}
      />
      <AvailableProductsTableRow
        srnr={30003}
        text={'"Step to Open" gulvmerke, ETTERLYSENDE'}
        imagePath={step_to_open_sticker}
        price={225}
        number={nrOf30003}
        setNumber={setNrOf30003}
      />
      <AvailableProductsTableRow
        srnr={40001}
        text={"Brakett for takmontering (kun til artikkel 10002)"}
        imagePath={bracket}
        price={300}
        number={nrOf40001}
        setNumber={setNrOf40001}
      />
      <AvailableProductsTableRow
        srnr={40002}
        text={"Lysfilter"}
        imagePath={lightFilter}
        price={50}
        number={nrOf40002}
        setNumber={setNrOf40002}
      />
      <AvailableProductsTableRow
        srnr={50001}
        text={"Overflaterens"}
        imagePath={cleaningWhipes}
        price={25}
        number={nrOf50001}
        setNumber={setNrOf50001}
      />
    </div>
  );
}
