import PageTemplate from "../pagetemplate/PageTemplate";
import hospital_image from "../../assets/images/installations/medical.jpg";
import office_image from "../../assets/images/installations/office.jpg";
import htoel_image from "../../assets/images/installations/hotel.jpg";
import parking_image from "../../assets/images/installations/parking.jpg";
import school_image from "../../assets/images/installations/school.jpg";
import Environment from "../../components/environments/Environment";
import { useTranslation } from "react-i18next";
import "./EnvironmentsPage.scss";

export default function EnvironmentsPage() {
  const { t, i18n } = useTranslation();

  const medialContent = [
    {
      subheading: t("environments-page.medical.subheading1"),
      text: t("environments-page.medical.text1"),
    },
    {
      subheading: t("environments-page.medical.subheading2"),
      text: t("environments-page.medical.text2"),
    },
    {
      subheading: t("environments-page.medical.subheading3"),
      text: t("environments-page.medical.text3"),
    },
    {
      subheading: t("environments-page.medical.subheading4"),
      text: t("environments-page.medical.text4"),
    },
  ];
  const officeContent = [
    {
      subheading: t("environments-page.office.subheading1"),
      text: t("environments-page.office.text1"),
    },
    {
      subheading: t("environments-page.office.subheading2"),
      text: t("environments-page.office.text2"),
    },
    {
      subheading: t("environments-page.office.subheading3"),
      text: t("environments-page.office.text3"),
    },
  ];
  const schoolContent = [
    {
      subheading: t("environments-page.school.subheading1"),
      text: t("environments-page.school.text1"),
    },
    {
      subheading: t("environments-page.school.subheading2"),
      text: t("environments-page.school.text2"),
    },
    {
      subheading: t("environments-page.school.subheading3"),
      text: t("environments-page.school.text3"),
    },
  ];
  const hotelContent = [
    {
      subheading: t("environments-page.hotel.subheading1"),
      text: t("environments-page.hotel.text1"),
    },
    {
      subheading: t("environments-page.hotel.subheading2"),
      text: t("environments-page.hotel.text2"),
    },
  ];
  const parkingContent = [
    {
      subheading: "",
      text: t("environments-page.parking.text1"),
    },
  ];

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="environments-page-container">
        <Environment
          title={t("environments-page.medical.title")}
          content={medialContent}
          imagePath={hospital_image}
          id={"environment-medical"}
        />
        <Environment
          title={t("environments-page.office.title")}
          content={officeContent}
          imagePath={office_image}
          id={"environment-office"}
        />
        <Environment
          title={t("environments-page.hotel.title")}
          content={hotelContent}
          imagePath={htoel_image}
          id={"environment-hotel"}
        />
        <Environment
          title={t("environments-page.school.title")}
          content={schoolContent}
          imagePath={school_image}
          id={"environment-school"}
        />
        <Environment
          title={t("environments-page.parking.title")}
          content={parkingContent}
          imagePath={parking_image}
          id={"environment-parking"}
        />
      </div>
    </PageTemplate>
  );
}
