import "./HomePage.scss";
import "../../components/HomePage/HomePageSection.scss";

import { Button, Column, Section, Title } from "trunx";
import PageTemplate from "../pagetemplate/PageTemplate";
import UniqueSellingPointsDisplayer from "../../components/HomePage/UniqueSellingPointsDisplayer";
import HomePageTextSection from "../../components/HomePage/HomePageTextSection";
import HyCarousel from "../../components/HomePage/HyCarousel";
import HomePageSectionTextAndImage from "../../components/HomePage/HomePageSectionTextAndImage";
import raiseToOpen from "../../assets/images/div/raiseToOpen.png";
import stepToOpen from "../../assets/images/div/stepToOpen.png";
import { useTranslation } from "react-i18next";
import plasseringsvennlig from "../../assets/svg/usp/plasseringsvennlig.svg";
import brukervennlig from "../../assets/svg/usp/brukervennlig.svg";
import hai from "../../assets/svg/usp/hai.svg";
import flyt from "../../assets/svg/usp/flyt.svg";
import penger from "../../assets/svg/usp/money.svg";
import kortreist from "../../assets/svg/usp/kortreist.svg";
import Counter from "../../components/HomePage/Counter";

export default function HomePage() {
  const { t, i18n } = useTranslation();

  const argumentsToDisplay = [
    {
      title: t("unique-selling-points.usp1.title"),
      text: t("unique-selling-points.usp1.text-brief"),
      imageSrc: hai,
      id: 1,
    },
    {
      title: t("unique-selling-points.usp2.title"),
      text: t("unique-selling-points.usp2.text-brief"),
      imageSrc: plasseringsvennlig,
      id: 2,
    },
    {
      title: t("unique-selling-points.usp3.title"),
      text: t("unique-selling-points.usp3.text-brief"),
      imageSrc: brukervennlig,
      id: 3,
    },
    {
      title: t("unique-selling-points.usp4.title"),
      text: t("unique-selling-points.usp4.text-brief"),
      imageSrc: penger,
      id: 4,
    },
    {
      title: t("unique-selling-points.usp5.title"),
      text: t("unique-selling-points.usp5.text-brief"),
      imageSrc: flyt,
      id: 5,
    },
    {
      title: t("unique-selling-points.usp6.title"),
      text: t("unique-selling-points.usp6.text-brief"),
      imageSrc: kortreist,
      id: 6,
    },
  ];
  return (
    <PageTemplate isNavbarTransparentToSolid={true}>
      <div className="homepage-video-container" id="homepage-video-container">
        <video
          autoPlay
          muted
          loop
          playsInline
          id="hytechVideo"
          className="homepage-video"
          src="/Hygotechweb-loop.mp4"
        ></video>
        <div className="homepage-video-text-container">
          <Title mt6 hasTextWhite>
            {t("home-page.video.title")}
          </Title>

          <p>{t("home-page.video.text")}</p>
          {/* <Counter /> */}

          <Button
            mt5
            isRounded
            isPrimary
            isMedium
            style={{ maxWidth: "250px" }}
            onClick={() => {
              window.dataLayer.push({ eventProps: undefined });
              window.dataLayer.push({
                event: "CTA-homepage",
              });
              const newPath =
                window.location.href.split("#")[0] +
                "#homepage-argument-displayer";
              window.location.href = newPath;
            }}
          >
            {t("home-page.video.call-to-action")}
          </Button>
        </div>
      </div>
      <div className="home-page-text-container">
        <UniqueSellingPointsDisplayer
          heading={t("home-page.usp-displayer-title")}
          argumentsToDisplay={argumentsToDisplay}
        />

        <Section
          p0
          className="section-container"
          id="homepage-carousel-section"
        >
          <HomePageTextSection
            title={t("home-page.i2Open.title")}
            content={[t("home-page.i2Open.text")]}
          />
          <Column is5Desktop is8Mobile id="homepage-carousel">
            <HyCarousel
              isInfiniteLoop={true}
              isAutoPlay={true}
              images={imageCarousel1}
            />
          </Column>
        </Section>

        <Section
          p0
          className="section-container"
          id="homepage-carousel-section"
        >
          <HomePageSectionTextAndImage
            title={t("home-page.step-to-open.title")}
            textContent={[t("home-page.step-to-open.text")]}
            imageSrc={stepToOpen}
            id={"step-to-open"}
            imageLeft={false}
          />

          <HomePageSectionTextAndImage
            title={t("home-page.raise-to-open.title")}
            textContent={[t("home-page.raise-to-open.text")]}
            imageSrc={raiseToOpen}
            id={"raise-to-open"}
            imageLeft={true}
          />
        </Section>
      </div>
    </PageTemplate>
  );
}

const imageCarousel1 = [
  "carousel1/downlight.jpeg",
  "carousel1/housingTransparent.png",
];
