import React, { useEffect, useState, useContext } from "react";
import { auth } from "../Firebase";
import {
  UserCredential,
  User,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

interface AuthContextProps {
  currentUser: FirebaseUser | null;
  login: loginType;
  signOut: signOutType;
  resetPassword: (email: string) => Promise<void>;
}

export type FirebaseUserCredential = UserCredential;
export type FirebaseUser = User;
export type signUpType = (
  email: string,
  password: string
) => Promise<FirebaseUserCredential>;
export type loginType = (
  email: string,
  password: string
) => Promise<FirebaseUserCredential>;
export type signOutType = () => Promise<void>;

export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
);

export function useAuth(): AuthContextProps {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);

  function login(
    email: string,
    password: string
  ): Promise<FirebaseUserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signOut(): Promise<void> {
    return auth.signOut();
  }

  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setCurrentUser(firebaseUser);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signOut,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
