import {collection, addDoc, updateDoc, Timestamp, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../Firebase";

const ORDERSCOLLECTION = "ordersCollection";

export interface QuoteProps {
    id?: string;
    orgnr: number;
    name: string;
    company: string;
    phoneNumber: string;
    email: string;
    created?: Timestamp;
    nrOf10002: number;
    nrOf10003: number;
    nrOf30001: number;
    nrOf30002: number;
    nrOf30003: number;
    nrOf40001: number;
    nrOf40002: number;
    nrOf50001: number;
    postalCode: number;
    city: string;
    street: string;
    code?: string;
}

export async function addQuote({name, orgnr, company, phoneNumber, email, nrOf10002, nrOf10003, nrOf30001, nrOf30002, nrOf30003, nrOf40001, nrOf40002, nrOf50001, postalCode, street, city, code}: QuoteProps) {
    const docRef = await addDoc(collection(db, ORDERSCOLLECTION), {
        name: name,
        orgnr: orgnr,
        company: company,
        phoneNumber: phoneNumber,
        email: email,
        active: true,
        created: Timestamp.now(),
        nrOf10002: nrOf10002,
        nrOf10003: nrOf10003,
        nrOf30001: nrOf30001,
        nrOf30002: nrOf30002,
        nrOf30003: nrOf30003,
        nrOf40001: nrOf40001,
        nrOf40002: nrOf40002,
        nrOf50001: nrOf50001,
        street: street,
        postalCode: postalCode,
        city: city,
        code: code ?? ""

    })
    updateDoc(docRef, {id: docRef.id});
}

export async function deleteQuote(id: string) {
    await deleteDoc(doc(db, ORDERSCOLLECTION, id ))
}

export function markQuoteAsDone(id:string) {
    const docRef = doc(db, ORDERSCOLLECTION, id);
    updateDoc(docRef, {active: false});
}

export async function getAllQuotes() {
    return await getDocs(collection(db, ORDERSCOLLECTION))
}

export async function getAllActiveQuotes() {
    const ordersCollectionRef = collection(db, ORDERSCOLLECTION)
    const q = query(ordersCollectionRef, where("active", "==", true));
    return await getDocs(q);
}