interface HomePageTextSectionProps {
  title: string;
  content: string[];
}

export default function HomePageTextSection({
  title,
  content,
}: HomePageTextSectionProps) {
  return (
    <div className="section-text-container">
      <h2 className="section-text-title">{title}</h2>
      {content &&
        content.map((paragraph) => {
          return <p className="section-text-content excerpt">{paragraph}</p>;
        })}
    </div>
  );
}
