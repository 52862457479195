import "./AboutUsPage.scss";

import { Column, Image, Subtitle } from "trunx";
import Svein from "../../assets/images/employees/sveinJorgen.jpg";
import Tor from "../../assets/images/employees/Tor.jpg";
import Paul from "../../assets/images/employees/Paul.png";
import Morten from "../../assets/images/employees/Morten.jpg";
import PageTemplate from "../pagetemplate/PageTemplate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { allowedLanguages } from "../../utilities/Types";

const employees: HyEmployeeCardProps[] = [
  {
    name: "Svein Jørgen Fuglesang",
    title: { NO: "Daglig Leder", EN: "CEO" },
    phone: "971 53 078",
    email: "sjf@hygotech.com",
    image: Svein,
  },
  {
    name: "Tor Fagersand",
    title: { NO: "Salgsleder", EN: "CSO" },
    phone: "958 22 377",
    email: "tor.fagersand@hygotech.com",
    image: Tor,
  },
  {
    name: "Paul Fagersand",
    title: { NO: "Prosjektansvarlig", EN: "Project Manager" },
    phone: "905 01 190",
    email: "paul.fagersand@hygotech.com",
    image: Paul,
  },
  {
    name: "Morten Løhre",
    title: {
      NO: "Utviklingsansvarlig",
      EN: "CDO",
    },
    phone: "900 35 550",
    email: "morten@hygotech.com",
    image: Morten,
  },
];

interface HyEmployeeCardProps {
  name: string;
  title: { NO: string; EN: string };
  language?: allowedLanguages;
  phone: string;
  email: string;
  image: string;
}

function getEmailsrc(mail: string) {
  return "mailto:" + mail;
}

function HyEmployeeCard({
  name,
  title,
  phone,
  email,
  image,
  language,
}: HyEmployeeCardProps) {
  return (
    <Column pr2 pl2 isFlex isFlexDirectionColumn isAlignItemsFlexStart>
      <Image
        src={image}
        style={{
          width: "280px",
          height: "330px",
          objectFit: "cover",
          overflow: "hidden",
        }}
      />
      <Subtitle mb1 isSize4>
        {name}
      </Subtitle>
      <p>{language == allowedLanguages.NO ? title.NO : title.EN}</p>
      <p>
        {" "}
        {language == allowedLanguages.NO ? "Telefon:" : "Phone"} {phone}
      </p>
      <p>
        {language == allowedLanguages.NO ? "E-post:" : "E-mail"}{" "}
        <a
          style={{ color: "black", textDecoration: "none" }}
          href={getEmailsrc(email)}
        >
          {email}
        </a>
      </p>
    </Column>
  );
}

export default function AboutUsPage() {
  const { t, i18n } = useTranslation();
  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="about-us-page-container">
        <div className="about-us-page-text-container">
          <h1>{t("about-us-page.title")}</h1>
          <p>{t("about-us-page.text")}</p>
        </div>
        <div className="about-us-image-gallery">
          {employees.map((employee) => {
            return (
              <HyEmployeeCard
                name={employee.name}
                title={employee.title}
                language={i18n.language as allowedLanguages}
                phone={employee.phone}
                email={employee.email}
                image={employee.image}
              />
            );
          })}
        </div>
      </div>
    </PageTemplate>
  );
}
