export enum AppPaths {
    HOME = "/",
    ORDER_PAGE = "/order-page",
    ADMINDEMOORDERS = "/admin-demo-orders",
    LOGIN = "/login",
    CUSTOMER_SUPPORT = "/customer-support",
    ABOUT = "/about",
    ARTICLES = "/articles",
    ADMINISTER_ARTICLES = "/administer-articles",
    CREATE_ARTICLES = "/create-articles",
    HYGOTECH_VIDEO = "/display-video",
    REFRENCES = "/refrences",
    ADMINISTER_REFRENCES = "/administer-refrences",
    CREATE_REFRENCE = "/create-refrence",
    ENVIRONMENTS = "/environments",
    ADMIN = "/admin",
    DETAILED_ARTICLE_VIEW = "/detailed-article-view",
    DETAILED_REFRENCE_VIEW = "/detailed-refrence-view",
    PRODUCTS = "/products",
    USP_DETAILED_VIEW="/usp"
}