import { db, storage } from "../Firebase";
import {serverTimestamp, updateDoc, doc, addDoc, collection, deleteDoc, getDocs, getDoc, orderBy, query} from "firebase/firestore";
import {ref, uploadBytesResumable, getDownloadURL, deleteObject} from "firebase/storage";


export async function uploadArticleImage(file: any, id: string) {
    const articleStorageRef = ref(storage, `articles/${id}.png`);
    const metadata = {
        contentType: 'image/png'
      };
    const uploadTask = uploadBytesResumable(articleStorageRef, file, metadata);

    uploadTask.on('state_changed', 
        (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
        case 'paused':
            console.log('Upload is paused');
            break;
        case 'running':
            console.log('Upload is running');
            break;
        }
    }, 
    (error) => {
        console.log(error);
  }, 
  () => {
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      const data =  {id: id, imageUrl: downloadURL};
      updateArticle(id, data);
    });
  }
)}

export async function deleteArticleImage(id: string) {
    const articleStorageRef = ref(storage, `articles/${id}.png`);
    deleteObject(articleStorageRef).then(() => {
        console.log("image deleted successfully");
    }).catch((error) => {
        console.log(error);
    });
}

export async function addArticle({file, title, content, link, order}: {file: any; title: string; content: string; link: string, order: number}) { //ArticleCardProps){
    try {
        addDoc(collection(db, "articlesCollection"), {
           title: title, content: content, link: link, lastEdited: serverTimestamp(), order: order,
        }).then((docRef => {
            uploadArticleImage(file, docRef.id);                
        }))
    }
    catch (e) {
        console.log("Attempted to add data to articlesCollection but had the following error " + e);
    }
}

export async function updateArticle(id: string, data: any){
    console.log(id);
    console.log(data);
    try {
        const docRef = doc(db, "articlesCollection", id);
        await updateDoc(docRef, data);
    }
    catch (e) {
        console.log(e)
    }
}

export async function deleteArticle(id: string){
    try {
        deleteDoc(doc(db, "articlesCollection", id )).then(() => {
            deleteArticleImage(id);
        });
    }
    catch (e) {
        console.log("When attempting to delete document " + id + "the following error occured " + e);
    }
}

export async function getAllArticles(){
    return await getDocs(collection(db, "articlesCollection"))
}

export async function getAllArticlesInOrder() {
    const articlesRef = collection(db, "articlesCollection");
    const q = query(articlesRef, orderBy("order", "asc"))
    return await getDocs(q);
}

export async function getArticleById(id: string) {
    //try {
        const docRef = doc(db, "articlesCollection", id);
        return (await getDoc(docRef)).data();
    //}
    /*catch (e) {
        console.log(e)
    }*/
}