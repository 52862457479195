import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./assets/locales/en.json";
import translationsNO from "./assets/locales/no.json";

i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationsEN,
      },
      no: {
        translation: translationsNO,
      },
    },
    lng: "no",
    fallbackLng: "no",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
