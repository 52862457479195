import { useEffect, useState } from "react";
import BackButton from "../../components/BackButton";
import UspDetailedView, {
  UspDetailedViewProps,
} from "../../components/uspDetailedView/UspDetailedView";
import wheelchair from "../../assets/gifs/wheelchair.gif";
import PageTemplate from "../pagetemplate/PageTemplate";
import child from "../../assets/gifs/child.gif";
import placementFirendly from "../../assets/images/div/plasseringsvennlig.jpg";
import { useTranslation } from "react-i18next";
import lifesyclus from "../../assets/images/div/livsykluskost.png";
import lifesyclus_en from "../../assets/images/div/lifecyclecost_en.png";
import sustainability_en from "../../assets/images/div/sustainable_en.png";
import sustainability from "../../assets/images/div/usp6.png";
import { allowedLanguages } from "../../utilities/Types";

export default function UspDetailedViewPage() {
  const queryString = window.location.href.split("?")[1];
  const urlParams = new URLSearchParams(queryString);
  const id = Number(urlParams.get("id"));
  const { t, i18n } = useTranslation();

  function getInfo(id: number, argumentsToDisplayNO: UspDetailedViewProps[]) {
    const shownArgument = argumentsToDisplayNO.filter((arg) => arg.id == id)[0];
    return shownArgument;
  }

  const [info, setInfo] = useState<UspDetailedViewProps>();

  useEffect(() => {
    if (id) {
      const test = getInfo(id, detailedUSPContent as UspDetailedViewProps[]);
      setInfo(test);
    }
  }, []);

  const detailedUSPContent = [
    {
      id: 1,
      title: t("unique-selling-points.usp1.title"),
      imageSrc: "carousel1/downlight.jpeg",
      text: t("unique-selling-points.usp1.text-detailed"),
    },
    {
      id: 2,
      title: t("unique-selling-points.usp2.title"),
      imageSrc: placementFirendly,
      text: t("unique-selling-points.usp2.text-detailed"),
    },
    {
      id: 3,
      title: t("unique-selling-points.usp3.title"),
      imageSrc: child,
      text: t("unique-selling-points.usp3.text-detailed"),
    },
    {
      id: 4,
      title: t("unique-selling-points.usp4.title"),
      imageSrc:
        i18n.language === allowedLanguages.NO ? lifesyclus : lifesyclus_en,
      text: t("unique-selling-points.usp4.text-detailed"),
    },
    {
      id: 5,
      title: t("unique-selling-points.usp5.title"),
      imageSrc: wheelchair,
      text: t("unique-selling-points.usp5.text-detailed"),
    },
    {
      id: 6,
      title: t("unique-selling-points.usp6.title"),
      imageSrc:
        i18n.language === allowedLanguages.NO
          ? sustainability
          : sustainability_en,
      text: t("unique-selling-points.usp6.text-detailed"),
    },
  ];

  if (info) {
    return (
      <PageTemplate isNavbarTransparentToSolid={false}>
        <BackButton />
        <UspDetailedView
          title={info?.title}
          text={info?.text}
          imageSrc={info?.imageSrc}
          id={id}
        />
      </PageTemplate>
    );
  }
  return <></>;
}
