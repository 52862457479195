import "./Environment.scss";

interface EnvironmentProps {
  title: string;
  content: { subheading: string; text: string }[];
  imagePath: string;
  id?: string;
}

export default function Environment({
  title,
  content,
  imagePath,
  id,
}: EnvironmentProps) {
  return (
    <div className="environment-container" id={id}>
      <img src={imagePath} alt="" />
      <EnvironmentContent content={content} title={title} />
    </div>
  );
}

interface EnvironmentContentProps {
  content: { subheading: string; text: string }[];
  title: string;
}

function EnvironmentContent({ content, title }: EnvironmentContentProps) {
  return (
    <div className="environment-title-and-text">
      <h2>{title}</h2>
      {content.map((entry) => (
        <>
          {entry.subheading && (
            <p className="environment-subheading">{entry.subheading}</p>
          )}{" "}
          {entry.text && <p className="environment-text">{entry.text}</p>}
        </>
      ))}{" "}
    </div>
  );
}
