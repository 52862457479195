import "./RefrencesPage.scss";

import { useEffect, useState } from "react";
import RefrenceCard, {
  refrenceCardProps,
} from "../../components/refrences/RefrenceCard";
import { getAllRefrencesInOrder } from "../../services/RefrenceService";
import PageTemplate from "../pagetemplate/PageTemplate";

export default function RefrencesPage() {
  const [refrences, setRefrences] = useState<refrenceCardProps[]>([]);

  useEffect(() => {
    getRefrences();
  }, []);

  async function getRefrences() {
    let tmpRefrences: any[] = [];
    await getAllRefrencesInOrder().then((refrences) => {
      console.log(refrences);
      refrences.forEach((refrence) => tmpRefrences.push(refrence.data()));
    });
    setRefrences(tmpRefrences);
  }

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="references-page-container">
        <div className="refrences-card-displayer">
          {refrences.map((refrence) => {
            return (
              <RefrenceCard
                id={refrence.id}
                title={refrence.title}
                logoUrl={refrence.logoUrl}
                imageUrl={refrence.imageUrl}
                content={refrence.content}
                lastEdited={refrence.lastEdited}
              />
            );
          })}
        </div>
      </div>
    </PageTemplate>
  );
}
