import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwZLfvMrWWImt-jjZ_vLAGO_syOgXlmqc",
  authDomain: "hygotech-4c76f.firebaseapp.com",
  projectId: "hygotech-4c76f",
  storageBucket: "hygotech-4c76f.appspot.com",
  messagingSenderId: "889345650252",
  appId: "1:889345650252:web:d1b958ea8ad71437344ee9",
  measurementId: "G-L5WVS1HFJN"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export default app;
