import { db, storage } from "../Firebase";
import {serverTimestamp, updateDoc, doc, addDoc, collection, deleteDoc, getDocs, getDoc, query, orderBy} from "firebase/firestore";
import {ref, uploadBytesResumable, getDownloadURL, deleteObject} from "firebase/storage";


export async function uploadRefrenceImage(file: any, id: string) {
    const refrencesStorageRef = ref(storage, `refrences/images/${id}.png`);
    const metadata = {
        contentType: 'image/png'
      };
    const uploadTask = uploadBytesResumable(refrencesStorageRef, file, metadata);

    uploadTask.on('state_changed', 
        (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
        case 'paused':
            console.log('Upload is paused');
            break;
        case 'running':
            console.log('Upload is running');
            break;
        }
    }, 
    (error) => {
        console.log(error);
  }, 
  () => {
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      const data =  {id: id, imageUrl: downloadURL};
      updateRefrence(id, data);
    });
  }
)}

export async function uploadRefrenceLogo(file: any, id: string) {
    const refrencesStorageRef = ref(storage, `refrences/logo/${id}.png`);
    const metadata = {
        contentType: 'image/png'
      };
    const uploadTask = uploadBytesResumable(refrencesStorageRef, file, metadata);

    uploadTask.on('state_changed', 
        (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
        case 'paused':
            console.log('Upload is paused');
            break;
        case 'running':
            console.log('Upload is running');
            break;
        }
    }, 
    (error) => {
        console.log(error);
  }, 
  () => {
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      const data =  {id: id, logoUrl: downloadURL};
      updateRefrence(id, data);
    });
  }
)}

export async function deleteRefrenceImage(id: string) {
    const refrencesStorageRef = ref(storage, `refrences/${id}.png`);
    deleteObject(refrencesStorageRef).then(() => {
        console.log("image deleted successfully");
    }).catch((error) => {
        console.log(error);
    });
}

export async function addRefrence({image, logo, title, content, order}: {image: any; logo: any; title: string; content: string; order?: number}) {
    try {
        addDoc(collection(db, "refrencesCollection"), {
           title: title, content: content, lastEdited: serverTimestamp(), order: order,
        }).then((docRef => {
            uploadRefrenceImage(image, docRef.id); 
            uploadRefrenceLogo(logo, docRef.id)               
        }))
    }
    catch (e) {
        console.log("Attempted to add data to refrencesCollection but had the following error " + e);
    }
}

export async function updateRefrence(id: string, data: any){
    try {
        const docRef = doc(db, "refrencesCollection", id);
        await updateDoc(docRef, data);
    }
    catch (e) {
        console.log(e)
    }
}

export async function deleteRefrence(id: string){
    try {
        deleteDoc(doc(db, "refrencesCollection", id )).then(() => {
            deleteRefrenceImage(id);
        });
    }
    catch (e) {
        console.log("When attempting to delete document " + id + "the following error occured " + e);
    }
}

export async function getAllRefrences(){
    return await getDocs(collection(db, "refrencesCollection"))
}

export async function getAllRefrencesInOrder() {
    const refrencesRef = collection(db, "refrencesCollection");
    const q = query(refrencesRef, orderBy("order", "asc"))
    return await getDocs(q);
}

export async function getReferenceById(id: string) {
    //try {
        const docRef = doc(db, "refrencesCollection", id);
        return (await getDoc(docRef)).data();
    //}
    /*catch (e) {
        console.log(e)
    }*/
}