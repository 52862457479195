import "./UspDetailedView.scss";

export interface UspDetailedViewProps {
  title: string;
  text: string;
  imageSrc: string;
  imageSrc2?: string;
  id: number;
}

export default function UspDetailedView({
  title,
  text,
  imageSrc,
  imageSrc2,
}: UspDetailedViewProps) {
  return (
    <div className="usp-detailed-view-container">
      <img src={imageSrc} alt="" />
      <div className="usp-detailed-view-title-and-text">
        <h3 className="usp-detailed-view-title">{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
}
