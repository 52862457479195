import "./CustomerSupportPage.scss";

import { Button } from "trunx";
import PageTemplate from "../pagetemplate/PageTemplate";
import { useTranslation } from "react-i18next";
import { allowedLanguages } from "../../utilities/Types";

export default function CustomerSupportPage() {
  const { t } = useTranslation();

  const supportCards: CustomerSupportDownloadCardProps[] = [
    {
      title: t("customer-support-page.folders.installation-10002.title"),
      downloadPath: {
        NO: "pdfs/Installasjonsveiledning.pdf",
        EN: "pdfs/Installasjonsveiledning.pdf",
      },
    },
    {
      title: t("customer-support-page.folders.installation-10003.title"),
      downloadPath: {
        NO: "pdfs/Installasjonsveiledning_Innfellbar.pdf",
        EN: "pdfs/Installasjonsveiledning_Innfellbar.pdf",
      },
    },
    {
      title: t("customer-support-page.folders.fdv-i2Open-10002.title"),
      downloadPath: {
        NO: "pdfs/FDV_10002_Standard.pdf",
        EN: "pdfs/FDV_10002_Standard.pdf",
      },
    },
    {
      title: t("customer-support-page.folders.fdv-i2Open-10003.title"),
      downloadPath: {
        NO: "pdfs/FDV_10003_Innfellbar.pdf",
        EN: "pdfs/FDV_10003_Innfellbar.pdf",
      },
    },
    {
      title: t("customer-support-page.folders.brochoure.title"),
      downloadPath: {
        NO: "pdfs/salgsbrosjyre_2023.pdf",
        EN: "pdfs/Hygotech-Folder-EN.pdf",
      },
    },
    {
      title: t("customer-support-page.folders.sale-and-delivery.title"),
      downloadPath: {
        NO: "pdfs/sale_and_delivery_terms.pdf",
        EN: "pdfs/sale_and_delivery_terms.pdf",
      },
    },
    {
      title: t("customer-support-page.folders.placement.title"),
      downloadPath: {
        NO: "pdfs/Plasseringsveiledning.pdf",
        EN: "pdfs/Plasseringsveiledning.pdf",
      },
    },
  ];

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="customer-support-page-container">
        <div className="customer-support-page-text-container">
          <h1>{t("customer-support-page.title")}</h1>
          <p>{t("customer-support-page.text")}</p>
        </div>
        <div className="customer-support-downloads-displayer">
          {supportCards.map((supportCard) => {
            return (
              <CustomerSupportDownloadCard
                title={supportCard.title}
                downloadPath={supportCard.downloadPath}
              />
            );
          })}
        </div>
      </div>
    </PageTemplate>
  );
}

interface CustomerSupportDownloadCardProps {
  title: string;
  downloadPath: {
    NO: string;
    EN: string;
  };
}

function CustomerSupportDownloadCard({
  title,
  downloadPath,
}: CustomerSupportDownloadCardProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="customer-support-download-card">
      <h4>{title}</h4>
      <Button
        isRounded
        isPrimary
        style={{ width: "125px", marginBottom: "5px" }}
        onClick={() => {
          window.dataLayer.push({ eventProps: undefined });
          window.dataLayer.push({
            event: "downloadCustomerSupportFile",
            eventProps: {
              fileName:
                i18n.language == allowedLanguages.NO
                  ? downloadPath.NO
                  : downloadPath.EN,
              currentLanguage: i18n.language,
            },
          });
        }}
      >
        <a
          href={
            i18n.language == allowedLanguages.NO
              ? downloadPath.NO
              : downloadPath.EN
          }
          download
        >
          {t("customer-support-page.download")}
        </a>
      </Button>
    </div>
  );
}
