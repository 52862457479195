import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "trunx";
import { AppPaths } from "../../AppPaths";
import { useAuth } from "../../contexts/AuthContex";
import {
  addRefrence,
  getAllRefrences,
  getReferenceById,
  updateRefrence,
} from "../../services/RefrenceService";
import PageTemplate from "../pagetemplate/PageTemplate";

export default function CreateArticlesPage() {
  const { currentUser } = useAuth();

  const history = useHistory();

  const [referenceTitle, setReferenceTitle] = useState<string>();
  const [referenceContent, setReferenceContent] = useState<string>();
  const [referenceImage, setReferenceImage] = useState<any>();
  const [referenceLogo, setReferenceLogo] = useState<any>();
  const [referenceOrder, setReferenceOrder] = useState<number>();

  useEffect(() => {
    getAllRefrences()
      .then((data) => console.log(data.docs))
      .catch((e) => console.log(e));
  }, []);

  const currentId = history.location.state as string;

  useEffect(() => {
    if (currentId) {
      getReferenceById(currentId).then((data) => {
        setReferenceTitle(data?.title);
        setReferenceContent(data?.content);
        setReferenceOrder(data?.order);
      });
    }
  }, []);

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setReferenceImage(event.target.files[0]);
    }
  };
  const onLogoChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setReferenceLogo(event.target.files[0]);
    }
  };

  function onSubmit() {
    if (currentId) {
      const dataToUpdate = {
        title: referenceTitle ? referenceTitle : "",
        content: referenceContent ? referenceContent : "",
        order: referenceOrder ? referenceOrder : 1000,
      };
      updateRefrence(currentId, dataToUpdate)
        .then(() => history.push(AppPaths.ADMIN))
        .catch((e) => console.log(e));
    } else {
      addRefrence({
        image: referenceImage,
        logo: referenceLogo,
        title: referenceTitle ? referenceTitle : "",
        content: referenceContent ? referenceContent : "",
        order: referenceOrder ? referenceOrder : 1000,
      })
        .then(() => {
          history.push(AppPaths.ADMIN);
        })
        .catch((e) => console.log(e));
    }
  }

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      {currentUser != null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="reference-title-input">Tittel</label>
            <input
              type="text"
              placeholder="tittel"
              id="reference-title-input"
              onChange={(e) => setReferenceTitle(e.target.value)}
              style={{ marginBottom: "1rem" }}
              value={referenceTitle}
            />

            <label htmlFor="reference-image-input">Bilde</label>
            <input
              type="file"
              placeholder="Bilde"
              id="reference-image-input"
              onChange={onImageChange}
              style={{ marginBottom: "1rem" }}
            />

            <label htmlFor="reference-image-input">Logo</label>
            <input
              type="file"
              placeholder="Logo"
              id="reference-image-input"
              onChange={onLogoChange}
              style={{ marginBottom: "1rem" }}
            />

            <label htmlFor="reference-order-input">Plassnummer</label>
            <input
              type="number"
              placeholder="Rekkefølge"
              id="reference-order-input"
              onChange={(e) => {
                const value = Number(e.target.value);
                setReferenceOrder(value);
              }}
              style={{ marginBottom: "1rem" }}
              value={referenceOrder}
            />

            <label htmlFor="reference-content-input">Innhold</label>
            <textarea
              placeholder="Innhold"
              id="reference-contet-input"
              onChange={(e) => setReferenceContent(e.target.value)}
              style={{ width: "300px", height: "200px", marginBottom: "1rem" }}
              value={referenceContent}
            />

            <Button isRounded isPrimary onClick={onSubmit}>
              {currentId ? "Rediger referanse" : "Opprett referanse"}
            </Button>
          </div>
        </div>
      ) : null}
    </PageTemplate>
  );
}
