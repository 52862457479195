import {collection, addDoc, updateDoc, Timestamp, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../Firebase";

const DEMOORDERSCOLLECTION = "demoOrdersCollection";

export interface DemoOrderProps {
    id?: string;
    name: string;
    company: string;
    phoneNumber: string;
    email: string;
    created?: Timestamp;
}

export async function addDemoOrder({name, company, phoneNumber, email}: DemoOrderProps) {
    const docRef = await addDoc(collection(db, DEMOORDERSCOLLECTION), {
        name: name,
        company: company,
        phoneNumber: phoneNumber,
        email: email,
        active: true,
        created: Timestamp.now(),
    })
    updateDoc(docRef, {id: docRef.id});



}

export async function deleteDemoOrder(id: string) {
    await deleteDoc(doc(db, DEMOORDERSCOLLECTION, id ))
}

export function markDemoOrderAsDone(id:string) {
    const docRef = doc(db, DEMOORDERSCOLLECTION, id);
    updateDoc(docRef, {active: false});
}

export async function getAllDemoOrders() {
    return await getDocs(collection(db, DEMOORDERSCOLLECTION))
}

export async function getAllActiveDemoOrders() {
    const ordersCollectionRef = collection(db, DEMOORDERSCOLLECTION)
    const q = query(ordersCollectionRef, where("active", "==", true));
    return await getDocs(q);
}