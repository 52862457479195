import "./AdminDashboardPage.scss";

import { useHistory } from "react-router-dom";
import PageTemplate from "../pagetemplate/PageTemplate";
import AdminTable, {
  AdminTableOptions,
} from "../../components/admin/AdminTable";
import { useState } from "react";
import { AppPaths } from "../../AppPaths";
import TabMenu from "../../components/admin/TabMenu";

export default function AdminDashboardPage() {
  const history = useHistory();
  const [chosenTab, setChosenTab] = useState<string>(AdminTableOptions.ORDERS);
  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <div className="admin-dashboard-container">
        <TabMenu
          options={["articles", "references", "orders", "demo-orders"]}
          chosenTab={chosenTab}
          setChosenTab={setChosenTab}
        />
        <AdminTable typeOfTable={chosenTab} />
        {renderCreateNew(history, chosenTab)}
      </div>
    </PageTemplate>
  );
}

function renderCreateNew(history: any, typeOfTable: string) {
  switch (typeOfTable) {
    case AdminTableOptions.ARTICLES:
      return (
        <button
          style={{ margin: "2rem" }}
          onClick={() => history.push(AppPaths.CREATE_ARTICLES)}
        >
          Opprett ny artikkel
        </button>
      );
    case AdminTableOptions.REFRENCES:
      return (
        <button
          style={{ margin: "2rem" }}
          onClick={() => history.push(AppPaths.CREATE_REFRENCE)}
        >
          Opprett ny referanse
        </button>
      );
  }
}
