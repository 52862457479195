import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export interface HyCarouselProps {
  images: string[];
  isAutoPlay?: boolean;
  isInfiniteLoop?: boolean;
  isShowArrows?: boolean;
  interval?: number;
}

export default function HyCarousel({
  images,
  isAutoPlay,
  isInfiniteLoop,
  isShowArrows,
  interval,
}: HyCarouselProps) {
  return (
    <Carousel
      showStatus={false}
      autoPlay={isAutoPlay}
      infiniteLoop={isInfiniteLoop}
      showArrows={isShowArrows}
      showThumbs={false}
      interval={interval}
    >
      {images.map((image) => {
        return (
          <div>
            <img
              src={image}
              alt="Feil ved henting av bilde"
              style={{ maxWidth: "600px", borderRadius: "15px" }}
            />
          </div>
        );
      })}
    </Carousel>
  );
}
