import ArgumentCard, { ArgumentCardProps } from "./ArgumentCard";
import "./UniqueSellingPointsDisplayer.scss";

interface UniqueSellingPointsDisplayerProps {
  argumentsToDisplay: ArgumentCardProps[];
  heading: string;
}

export default function UniqueSellingPointsDisplayer({
  argumentsToDisplay,
  heading,
}: UniqueSellingPointsDisplayerProps) {
  return (
    <div
      className="homepage-argument-displayer"
      id="homepage-argument-displayer"
    >
      <h1 className="section-text-title">{heading}</h1>
      <div className="homepage-argument-container">
        {argumentsToDisplay.map((argumentsToDisplay) => {
          return (
            <ArgumentCard
              title={argumentsToDisplay.title}
              text={argumentsToDisplay.text}
              imageSrc={argumentsToDisplay.imageSrc}
              id={argumentsToDisplay.id}
            />
          );
        })}
      </div>
    </div>
  );
}
