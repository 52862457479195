import "./HomePageSection.scss";

import HomePageTextSection from "./HomePageTextSection";

interface HomePageSectionTextAndImageProps {
  title: string;
  textContent: string[];
  imageSrc: string;
  imageLeft: boolean;
  id: string;
}

export default function HomePageSectionTextAndImage({
  title,
  textContent,
  imageSrc,
  imageLeft,
  id,
}: HomePageSectionTextAndImageProps) {
  return (
    <div id={id} className="section-container">
      {imageLeft ? (
        <HomePageTextSection title={title} content={textContent} />
      ) : (
        <img src={imageSrc} className="section-image" />
      )}

      {imageLeft ? (
        <img src={imageSrc} className="section-image" />
      ) : (
        <HomePageTextSection title={title} content={textContent} />
      )}
    </div>
  );
}
