import PageTemplate from "./pagetemplate/PageTemplate";
import { Column } from "trunx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { allowedLanguages } from "../utilities/Types";
import { changeLanguage } from "i18next";

export default function HyVideoPage() {
  const { i18n } = useTranslation();
  const source =
    i18n.language == allowedLanguages.NO
      ? "videos/Hygotech_main_NO.mp4"
      : "videos/Hygotech_main_EN.mp4";

  useEffect(() => {
    const url = window.location.href;
    const queryParams = url.split("?");
    try {
      if (queryParams.length == 2) {
        const language = queryParams[1].split("=")[1];
        if (language === "en") {
          changeLanguage("en");
        }
      }
    } catch (e) {
      console.log(e);
    }
    window.dataLayer.push({ eventProps: undefined });
    window.dataLayer.push({
      event: "userViewedVideo",
      eventProps: { language: i18n.language },
    });
  }, []);

  function changeSource(source: string) {
    var video = document.getElementById("video") as HTMLVideoElement;
    video.src = source;
    video.play();
  }

  useEffect(() => {
    if (i18n.language === allowedLanguages.NO) {
      changeSource("videos/Hygotech_main_NO.mp4");
    } else {
      changeSource("videos/Hygotech_main_EN.mp4");
    }
  }, [i18n.language]);

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <Column
        isFlex
        isFlexDirectionRow
        isJustifyContentCenter
        isAlignItemsCenter
        is12
        p0
        style={{
          padding: "0px !important",
          minHeight: "75vh",
          backgroundColor: "black",
        }}
      >
        <video id="video" controls autoPlay preload="auto">
          <source src={source} />
        </video>
      </Column>
    </PageTemplate>
  );
}
