import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppPaths } from "../AppPaths";
import { HashLink as Link } from "react-router-hash-link";

export default function BackButton() {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Link
      style={{
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "black",
        padding: "0.5rem",
      }}
      to="/#homepage-argument-displayer"
    >
      <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faChevronLeft} />
      <p style={{ borderBottom: "1px solid black" }}>{t("back-button")}</p>
    </Link>
  );
}
