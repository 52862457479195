export default function TabMenu({
  options,
  chosenTab,
  setChosenTab,
}: {
  options: string[];
  chosenTab: string;
  setChosenTab: (newTab: string) => void;
}) {
  return (
    <div className="tab-menu">
      {options.map((option) => {
        return (
          <p
            className={chosenTab == option ? "chosen" : ""}
            onClick={() => setChosenTab(option)}
          >
            {option}
          </p>
        );
      })}
    </div>
  );
}
