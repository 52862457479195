import { useState } from "react";
import { Button } from "trunx";
import { addQuote } from "../../services/QuoteService";
import HyInputField from "../form/HyInputField";
import { Orgnr } from "../../utilities/Orgnr";
import AvailableProductsTable from "./AvailableProductsTable";
import "./MultistepOrderForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import AvailableProductsTableAccessories from "./AvailableProductsTableAccessories";
import { Message } from "trunx";
import SuccessfulOrderModal from "./SuccessfulOrderModal";
import { AppPaths } from "../../AppPaths";
import { useHistory } from "react-router-dom";

export default function MultiStepOrderForm() {
  const [showStep, setShowStep] = useState<number>(1);
  const [successfullOrder, setSuccessfullorder] = useState<boolean>(false);

  const [nrOf10002, setNrOf10002] = useState<number>(0);
  const [nrOf10003, setNrOf10003] = useState<number>(0);
  const [nrOf30001, setNrOf30001] = useState<number>(0);
  const [nrOf30002, setNrOf30002] = useState<number>(0);
  const [nrOf30003, setNrOf30003] = useState<number>(0);
  const [nrOf40001, setNrOf40001] = useState<number>(0);
  const [nrOf40002, setNrOf40002] = useState<number>(0);
  const [nrOf50001, setNrOf50001] = useState<number>(0);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [orgnr, setOrgNr] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [postalCode, setPostalCode] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const history = useHistory();

  const order = () => {
    setErrorMessage("");
    if (
      phoneNumber !== "" ||
      orgnr !== 0 ||
      email !== "" ||
      company !== "" ||
      postalCode !== 0 ||
      city !== ""
    ) {
      window.dataLayer.push({ eventProps: undefined });
      window.dataLayer.push({
        event: "order",
        eventProps: {
          name: name,
          company: company,
          email: email,
          phoneNumber: phoneNumber,
        },
      });
      addQuote({
        name: name,
        company: company,
        email: email,
        postalCode: postalCode,
        city: city,
        street: street,
        orgnr: orgnr,
        phoneNumber: phoneNumber,
        nrOf10002: nrOf10002,
        nrOf10003: nrOf10003,
        nrOf30001: nrOf30001,
        nrOf30002: nrOf30002,
        nrOf30003: nrOf30003,
        nrOf40001: nrOf40001,
        nrOf40002: nrOf40002,
        nrOf50001: nrOf50001,
        code: code,
      }).then(() => {
        setSuccessfullorder(true);
      });
    } else {
      setErrorMessage("Du må fylle alle disse feltene.");
    }
  };

  return (
    <div className="order-form-multistep-container">
      <ProgressBar state={showStep} />
      {successfullOrder && (
        <SuccessfulOrderModal
          isOpen={successfullOrder}
          setIsOpen={setSuccessfullorder}
        />
      )}
      {showStep === 1 && (
        <OrderFormStep1
          nrOf10002={nrOf10002}
          nrOf10003={nrOf10003}
          setNrOf10002={setNrOf10002}
          setNrOf10003={setNrOf10003}
        />
      )}
      {showStep === 2 && (
        <OrderFormStep2
          nrOf30001={nrOf30001}
          nrOf30002={nrOf30002}
          nrOf30003={nrOf30003}
          nrOf40001={nrOf40001}
          nrOf40002={nrOf40002}
          nrOf50001={nrOf50001}
          setNrOf30001={setNrOf30001}
          setNrOf30002={setNrOf30002}
          setNrOf30003={setNrOf30003}
          setNrOf40001={setNrOf40001}
          setNrOf40002={setNrOf40002}
          setNrOf50001={setNrOf50001}
        />
      )}
      {showStep === 3 && (
        <OrderFormStep3
          city={city}
          setCity={setCity}
          company={company}
          setCompany={setCompany}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          street={street}
          setStreet={setStreet}
          orgnr={orgnr}
          setOrgnr={setOrgNr}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          code={code}
          setCode={setCode}
        />
      )}
      <div
        style={
          showStep !== 3
            ? { backgroundColor: "#00d1b2", color: "#ffffff", width: "100%" }
            : { width: "100%" }
        }
      >
        {errorMessage && (
          <Message isDanger>
            <Message.Body>{errorMessage}</Message.Body>
          </Message>
        )}
        <div className="multistep-navigation-button-container">
          <Button
            disabled={showStep === 1}
            onClick={() => setShowStep(showStep - 1)}
          >
            Forrige
          </Button>
          {showStep === 3 && (
            <Button
              isPrimary
              onClick={order}
              disabled={
                phoneNumber === "" ||
                orgnr === 0 ||
                email === "" ||
                company === "" ||
                postalCode === 0 ||
                city === ""
              }
            >
              Bestill
            </Button>
          )}
          <Button
            disabled={showStep === 3}
            onClick={() => setShowStep(showStep + 1)}
          >
            Neste
          </Button>
        </div>
      </div>
    </div>
  );
}

export interface OrderFormStep1Props {
  nrOf10002: number;
  setNrOf10002: (nr: number) => void;
  nrOf10003: number;
  setNrOf10003: (nr: number) => void;
}

const OrderFormStep1 = ({
  nrOf10002,
  setNrOf10002,
  nrOf10003,
  setNrOf10003,
}: OrderFormStep1Props) => {
  return (
    <AvailableProductsTable
      nrOf10002={nrOf10002}
      nrOf10003={nrOf10003}
      setNrOf10002={setNrOf10002}
      setNrOf10003={setNrOf10003}
    />
  );
};

export interface OrderFormStep2Props {
  nrOf30001: number;
  setNrOf30001: (nr: number) => void;
  nrOf30002: number;
  setNrOf30002: (nr: number) => void;
  nrOf30003: number;
  setNrOf30003: (nr: number) => void;
  nrOf40001: number;
  setNrOf40001: (nr: number) => void;
  nrOf40002: number;
  setNrOf40002: (nr: number) => void;
  nrOf50001: number;
  setNrOf50001: (nr: number) => void;
}

const OrderFormStep2 = ({
  nrOf30001,
  nrOf30002,
  nrOf30003,
  nrOf40001,
  nrOf40002,
  nrOf50001,
  setNrOf30001,
  setNrOf30002,
  setNrOf30003,
  setNrOf40001,
  setNrOf40002,
  setNrOf50001,
}: OrderFormStep2Props) => {
  return (
    <AvailableProductsTableAccessories
      nrOf30001={nrOf30001}
      nrOf30002={nrOf30002}
      nrOf30003={nrOf30003}
      nrOf40001={nrOf40001}
      nrOf40002={nrOf40002}
      nrOf50001={nrOf50001}
      setNrOf30001={setNrOf30001}
      setNrOf30002={setNrOf30002}
      setNrOf30003={setNrOf30003}
      setNrOf40001={setNrOf40001}
      setNrOf40002={setNrOf40002}
      setNrOf50001={setNrOf50001}
    />
  );
};

export interface OrderFormStep3Props {
  name: string;
  setName: (name: string) => void;
  company: string;
  setCompany: (company: string) => void;
  orgnr: number;
  setOrgnr: (orgnr: number) => void;
  email: string;
  setEmail: (email: string) => void;
  postalCode: number;
  setPostalCode: (code: number) => void;
  city: string;
  setCity: (city: string) => void;
  street: string;
  setStreet: (street: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  code: string;
  setCode: (code: string) => void;
}

const OrderFormStep3 = ({
  name,
  setName,
  company,
  setCompany,
  orgnr,
  setOrgnr,
  email,
  setEmail,
  postalCode,
  setPostalCode,
  city,
  setCity,
  street,
  setStreet,
  phoneNumber,
  setPhoneNumber,
  code,
  setCode,
}: OrderFormStep3Props) => {
  return (
    <div style={{ width: "300px" }}>
      <HyInputField
        className="quote-form-input"
        id="name"
        placeholder="Navn"
        value={name}
        onChange={(e: any) => setName(e.target.value)}
        label="Navn"
      />
      <HyInputField
        className="quote-form-input"
        placeholder="Bedrift"
        label="Bedrift"
        id="order-form-company"
        value={company}
        onChange={(e: any) => setCompany(e.target.value)}
      />
      <HyInputField
        className="quote-form-input"
        placeholder="Orgnr"
        id="order-form-orgnr"
        label="Orgnr"
        value={orgnr}
        onChange={(e: any) => setOrgnr(e.target.value)}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <HyInputField
          className="quote-form-input"
          placeholder="Leveringsadresse"
          label="Leveringsadresse"
          id="order-form-street"
          value={street}
          onChange={(e: any) => setStreet(e.target.value)}
        />
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100px",
              marginRight: "10px",
            }}
          >
            <HyInputField
              className="quote-form-input"
              placeholder="Postkode"
              label="Postkode"
              id="order-form-postalCode"
              value={postalCode}
              required={true}
              onChange={(e: any) => setPostalCode(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <HyInputField
              className="quote-form-input"
              placeholder="Poststed"
              label="Poststed"
              id="order-form-city"
              value={city}
              onChange={(e: any) => setCity(e.target.value)}
              style={{ display: "flex", flexDirection: "column" }}
            />
          </div>
        </div>
      </div>

      <HyInputField
        className="quote-form-input"
        placeholder="Tlf"
        label="Tlf"
        id="order-form-phone"
        value={phoneNumber}
        onChange={(e: any) => setPhoneNumber(e.target.value)}
      />
      <HyInputField
        className="quote-form-input"
        placeholder="E-post"
        label="E-post"
        id="order-form-email"
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />

      <HyInputField
        className="quote-form-input"
        placeholder="Kampanjekode"
        label="Kampanjekode"
        id="order-form-code"
        value={code}
        onChange={(e: any) => setCode(e.target.value)}
      />
    </div>
  );
};

function ProgressBar({ state }: { state: number }) {
  return (
    <div className="order-form-progress-bar">
      <div
        style={{
          height: "5px",
          width: "100%",
          flexGrow: 1,
          backgroundColor: "#00d1b2",
        }}
      ></div>
      <p style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faCircle as IconDefinition}
          size={"2x"}
          color={"#00d1b2"}
        />
        <p
          style={{
            position: "absolute",
            top: "7px",
            left: "12px",
            fontWeight: 600,
            color: "#00d1b2",
          }}
        >
          1
        </p>
      </p>

      <div
        style={{
          height: "5px",
          width: "100%",
          flexGrow: 3,
          backgroundColor: state == 1 ? "gray" : "#00d1b2",
        }}
      ></div>
      <p style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faCircle as IconDefinition}
          size={"2x"}
          color={state == 1 ? "gray" : "#00d1b2"}
        />
        <p
          style={{
            position: "absolute",
            top: "7px",
            left: "12px",
            fontWeight: 600,
            color: state === 1 ? "gray" : "#00d1b2",
          }}
        >
          2
        </p>
      </p>
      <div
        style={{
          height: "5px",
          width: "100%",
          flexGrow: 1,
          backgroundColor: state == 1 ? "gray" : "#00d1b2",
        }}
      ></div>
      <p style={{ position: "relative" }}>
        <FontAwesomeIcon
          icon={faCircle as IconDefinition}
          size={"2x"}
          color={state !== 3 ? "gray" : "#00d1b2"}
        />
        <p
          style={{
            position: "absolute",
            top: "7px",
            left: "12px",
            fontWeight: 600,
            color: state !== 3 ? "gray" : "#00d1b2",
          }}
        >
          3
        </p>
      </p>
      <div
        style={{
          height: "5px",
          width: "100%",
          flexGrow: 1,
          backgroundColor: state !== 3 ? "gray" : "#00d1b2",
        }}
      ></div>
    </div>
  );
}
