import { Columns } from "trunx";
import ArticleCard, {
  ArticleCardProps,
} from "../../components/articles/ArticleCard";
import { useState, useEffect } from "react";
import PageTemplate from "../pagetemplate/PageTemplate";
import { getAllArticlesInOrder } from "../../services/ArticleService";
import "./ArticlesPage.scss";

export default function ArticlesPage() {
  const [articles, setArticles] = useState<ArticleCardProps[]>([]);

  async function getArticles() {
    const articles: any[] = [];
    await getAllArticlesInOrder().then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        articles.push(doc.data());
      });
    });
    setArticles(articles);
  }

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      <Columns
        isFlex
        isFlexDirectionColumn
        isJustifyContentCenter
        isAlignItemsCenter
        style={{ backgroundColor: "#6d899e" }}
        p5
      >
        <div className="articles-displayer">
          {articles?.map((article) => {
            return (
              <ArticleCard
                id={article.id}
                title={article.title}
                content={article.content}
                lastEdited={article.lastEdited}
                imageUrl={article.imageUrl}
                link={article.link}
              />
            );
          })}
        </div>
      </Columns>
    </PageTemplate>
  );
}
