import firebase from "firebase/app";
import { Timestamp } from "firebase/firestore";

export  function timestampFormater(timestamp: Timestamp): string {
    const date: Date = timestamp.toDate();
    const month: number = date.getUTCMonth() + 1;
    return (
      date.getFullYear().toString() +
      "/" +
      month.toString() +
      "/" +
      date.getUTCDate().toString()
    );
  }