import { Button, Column, Content, Dropdown, Image, Navbar, P } from "trunx";
import "./HyHeader.scss";
import logo from "../../assets/svg/logo_or_brand/logo.svg";
import logoTextLight from "../../assets/images/div/brand.png";
import logoTextDark from "../../assets/svg/logo_or_brand/hygotech.svg";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { AppPaths } from "../../AppPaths";
import { useAuth } from "../../contexts/AuthContex";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import NorwegianFlag from "../../assets/svg/flags/norway.svg";
import EnglishFlag from "../../assets/svg/flags/english.svg";
import { useTranslation } from "react-i18next";
import { allowedLanguages } from "../../utilities/Types";

interface HyHeaderProps {
  isNavbarTransparentToSolid: boolean;
}

export default function HyHeader({
  isNavbarTransparentToSolid,
}: HyHeaderProps) {
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);
  const history = useHistory();
  const { currentUser } = useAuth();
  const [navbarTransparent, setNavbarTransparent] = useState<boolean>(
    isNavbarTransparentToSolid
  );
  const { t, i18n } = useTranslation();

  function onScroll() {
    let vh: number = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    if (window.scrollY > vh - 400) {
      setNavbarTransparent(false);
    } else {
      if (isNavbarTransparentToSolid) {
        setNavbarTransparent(true);
      }
    }
  }
  useEffect(() => {
    document.addEventListener("scroll", onScroll);
  }, []);

  return (
    <Navbar
      pl4
      pr4
      className="HyNavbar"
      style={
        navbarTransparent
          ? {
              position: "sticky",
              top: "0",
              backgroundColor: "transparent",
              maxWidth: "100vw",
            }
          : {
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              maxWidth: "100vw",
            }
      }
    >
      <Navbar.Brand>
        <Image src={logo} />
        <Navbar.Item
          isSize3
          className={
            navbarTransparent ? "transparent header-link" : "solid header-link"
          }
          onClick={() => {
            const newPath =
              window.location.href.split("#")[0] + "#homepage-video-container";
            window.location.href = newPath;
          }}
        >
          <img
            src={navbarTransparent ? logoTextLight : logoTextDark}
            alt="Hygotech"
          />
        </Navbar.Item>
        <Navbar.Burger
          isActive={showBurgerMenu}
          onClick={() => setShowBurgerMenu(!showBurgerMenu)}
          style={navbarTransparent ? { color: "white" } : { color: "black" }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </Navbar.Burger>
      </Navbar.Brand>
      <Navbar.Menu isActive={showBurgerMenu}>
        <Navbar.Start>
          <Navbar.Item className="navbarItem">
            <Link
              to={AppPaths.HYGOTECH_VIDEO}
              className={
                navbarTransparent
                  ? "transparent header-link"
                  : "solid header-link"
              }
            >
              <Column p0 m0 isFlex isFlexDirectionRow isAlignItemsCenter>
                {navbarTransparent && window.innerWidth > 1025 ? (
                  <VideoSvg />
                ) : (
                  <VideoSvgDark />
                )}
                <Column ml2 p0 m0 isSize6 isFlex isFlexDirectionColumn>
                  <p className="header-link">
                    {t("navbar-footer.watch-video-1")}
                  </p>

                  {i18n.language == allowedLanguages.NO ? null : (
                    <p className="header-link" style={{ marginTop: "-10px" }}>
                      {t("navbar-footer.watch-video-2")}
                    </p>
                  )}
                  <p className="header-link" style={{ marginTop: "-10px" }}>
                    video
                  </p>
                </Column>
              </Column>
            </Link>
          </Navbar.Item>
        </Navbar.Start>
        <Navbar.End>
          <Navbar.Item className="navbarItem">
            <Dropdown isHoverable>
              <Dropdown.Trigger>
                <Button
                  p0
                  m0
                  style={
                    navbarTransparent
                      ? {
                          backgroundColor: "transparent",
                          border: "none",
                        }
                      : { border: "none" }
                  }
                  isSize5
                  className={
                    navbarTransparent
                      ? "transparent header-link"
                      : "solid header-link"
                  }
                >
                  <p className="header-link">
                    {t("navbar-footer.products.title")}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ marginLeft: "5px" }}
                    />
                  </p>
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Content
                  style={
                    navbarTransparent
                      ? { maxWidth: "250px", backgroundColor: "transparent" }
                      : { maxWidth: "250px" }
                  }
                >
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      to={{
                        pathname: AppPaths.PRODUCTS,
                        hash: "#i2OpenCeling",
                      }}
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                    >
                      <p className="header-link">
                        {t("navbar-footer.products.i2OpenCeiling")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.PRODUCTS,
                        hash: "#i2OpenWall",
                      }}
                    >
                      <p className="header-link">
                        {" "}
                        {t("navbar-footer.products.i2OpenWall")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.PRODUCTS,
                        hash: "#app",
                      }}
                    >
                      <p className="header-link">
                        {" "}
                        {t("navbar-footer.products.app")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.PRODUCTS,
                        hash: "#accessories",
                      }}
                    >
                      <p className="header-link">
                        {" "}
                        {t("navbar-footer.products.accessories")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Item>
          {currentUser && (
            <Navbar.Item>
              <Link
                className={
                  navbarTransparent
                    ? "transparent header-link"
                    : "solid header-link"
                }
                to={AppPaths.ADMIN}
              >
                <p className="header-link">Admin</p>
              </Link>
            </Navbar.Item>
          )}
          <Navbar.Item className="navbarItem">
            <Dropdown isHoverable>
              <Dropdown.Trigger>
                <Button
                  isSize5
                  p0
                  m0
                  className={
                    navbarTransparent
                      ? "transparent header-link"
                      : "solid header-link"
                  }
                  style={
                    navbarTransparent
                      ? {
                          backgroundColor: "transparent",
                          border: "none",
                        }
                      : { border: "none" }
                  }
                >
                  <p className="header-link">
                    {t("navbar-footer.environments.title")}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{ marginLeft: "5px" }}
                    />
                  </p>
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Content
                  style={
                    navbarTransparent
                      ? { maxWidth: "250px", backgroundColor: "transparent" }
                      : { maxWidth: "250px" }
                  }
                >
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.ENVIRONMENTS,
                        hash: "environment-medical",
                      }}
                    >
                      <p className="header-link">
                        {t("navbar-footer.environments.medical")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.ENVIRONMENTS,
                        hash: "environment-office",
                      }}
                    >
                      <p className="header-link">
                        {t("navbar-footer.environments.office")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.ENVIRONMENTS,
                        hash: "environment-school",
                      }}
                    >
                      <p className="header-link">
                        {t("navbar-footer.environments.school")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.ENVIRONMENTS,
                        hash: "environment-hotel",
                      }}
                    >
                      <p className="header-link">
                        {t("navbar-footer.environments.hotel")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Link
                      className={
                        navbarTransparent
                          ? "transparent header-link"
                          : "solid header-link"
                      }
                      to={{
                        pathname: AppPaths.ENVIRONMENTS,
                        hash: "environment-parking",
                      }}
                    >
                      <p className="header-link">
                        {t("navbar-footer.environments.parking")}
                      </p>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Item>
          <Navbar.Item className="navbarItem">
            <Link
              className={
                navbarTransparent
                  ? "transparent header-link"
                  : "solid header-link"
              }
              to={AppPaths.ARTICLES}
            >
              <p className="header-link">{t("navbar-footer.articles")}</p>
            </Link>
          </Navbar.Item>
          <Navbar.Item className="navbarItem">
            <Link
              className={
                navbarTransparent
                  ? "transparent header-link"
                  : "solid header-link"
              }
              to={AppPaths.REFRENCES}
            >
              <p className="header-link">{t("navbar-footer.references")}</p>
            </Link>
          </Navbar.Item>
          <Navbar.Item className="navbarItem">
            <Link
              to={AppPaths.CUSTOMER_SUPPORT}
              className={
                navbarTransparent
                  ? "transparent header-link"
                  : "solid header-link"
              }
            >
              <p className="header-link">
                {t("navbar-footer.customer-support")}
              </p>
            </Link>
          </Navbar.Item>

          <Navbar.Item className="navbarItem">
            <Link
              className={
                navbarTransparent
                  ? "transparent header-link"
                  : "solid header-link"
              }
              to={AppPaths.ABOUT}
            >
              <p className="header-link">{t("navbar-footer.about-us")}</p>
            </Link>
          </Navbar.Item>
          <Navbar.Item className="navbarItem">
            <Dropdown isHoverable>
              <Dropdown.Trigger>
                <Button
                  p0
                  m0
                  isSize5
                  hasTextWeightSemibold
                  style={
                    navbarTransparent
                      ? {
                          backgroundColor: "transparent",
                          border: "none",
                          color: "white",
                        }
                      : { border: "none" }
                  }
                  className="header-link"
                >
                  <Image
                    className="header-flag"
                    src={
                      i18n.language == allowedLanguages.NO
                        ? NorwegianFlag
                        : EnglishFlag
                    }
                  />

                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: "10px" }}
                  />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Content
                  style={
                    navbarTransparent
                      ? { maxWidth: "100px", backgroundColor: "transparent" }
                      : { maxWidth: "100px" }
                  }
                >
                  <Dropdown.Item
                    onClick={() => {
                      i18n.changeLanguage("no");
                      window.dataLayer.push({ eventProps: undefined });
                      window.dataLayer.push({
                        event: "changeLanguage",
                        eventProps: { currentLanguage: "ENGLISH" },
                      });
                    }}
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Image className="header-flag" src={NorwegianFlag} />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      i18n.changeLanguage("en");
                      window.dataLayer.push({ eventProps: undefined });
                      window.dataLayer.push({
                        event: "changeLanguage",
                        eventProps: { currentLanguage: "ENGLISH" },
                      });
                    }}
                    className={
                      navbarTransparent
                        ? "dropdown-item transparent"
                        : "dropdown-item solid"
                    }
                  >
                    <Image className="header-flag" src={EnglishFlag} />
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Item>
          <Navbar.Item>
            <Button
              isRounded
              isSmall
              isPrimary
              onClick={() => {
                history.push(AppPaths.ORDER_PAGE);
              }}
            >
              <p className="header-link">{t("navbar-footer.order")}</p>
            </Button>
          </Navbar.Item>
        </Navbar.End>
      </Navbar.Menu>
    </Navbar>
  );
}

function VideoSvg() {
  return (
    <svg
      width={44}
      height={33}
      viewBox="0 0 44 33"
      style={{ fill: "white" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.56 25.471s-.988 3.415-2.308 4.779c-1.672 1.791-3.677 2.171-4.538 2.276-.282.021-.573.04-.864.058l-.002-.012a1.342 1.342 0 01-1.072-.379 1.362 1.362 0 010-1.929c.242-.242.556-.358.871-.38l-.001-.016.14-.01c.713-.086 2.265-.093 3.652-1.574 1.093-1.126 1.448-3.686 1.448-3.686s.364-3.004.364-6.008v-4.194c0-3.005-.364-6.011-.364-6.011s-.355-2.56-1.448-3.686c-1.387-1.479-2.94-1.486-3.652-1.573C30.687 2.75 22 2.752 22 2.752s-8.687-.002-13.787.374c-.712.087-2.265.094-3.65 1.573C3.47 5.826 3.113 8.385 3.113 8.385c-.001 0-.364 3.006-.364 6.01v4.195c0 3.004.364 6.008.364 6.008-.001 0 .356 2.56 1.447 3.686 1.386 1.48 3.207 1.434 4.019 1.589 2.915.284 13.419.377 13.419.377a1.375 1.375 0 110 2.75c-.023 0-.045-.004-.068-.005-.868-.01-10.838-.121-14.204-.452-.98-.189-3.306-.502-4.98-2.293C1.426 28.886.44 25.471.44 25.471S0 22.521 0 18.884v-4.786C0 10.46.44 7.51.44 7.51s.986-3.394 2.306-4.757C4.421.96 6.424.56 7.284.455 13.443 0 21.991 0 21.991 0h.018s8.549 0 14.705.455c.861.105 2.866.505 4.538 2.297 1.32 1.363 2.309 4.757 2.309 4.757S44 10.46 44 14.1v4.785c0 3.638-.44 6.587-.44 6.587zM27.5 30.25a1.375 1.375 0 110 2.75 1.375 1.375 0 010-2.75zm-6.147-7.035l-.013.007a1.33 1.33 0 01-.694.197 1.35 1.35 0 01-.624-2.548l-.002-.001 7.014-3.74c-.004-.004-.015-.007-.008-.01a.687.687 0 00.388-.62.687.687 0 00-.338-.587l-8.21-4.388a.682.682 0 00-.984.575l.001 8.512h-.01l.001.014a1.376 1.376 0 01-2.75 0v-9.577a2.799 2.799 0 014.241-2.397c.017.009.023.006.037.012l9.485 5.465c.201.117.386.256.558.427a2.75 2.75 0 010 3.89 2.827 2.827 0 01-.514.4l-7.523 4.336c-.018.012-.037.021-.055.033z"
        fill="#fff"
      ></path>
    </svg>
  );
}

function VideoSvgDark() {
  return (
    <svg
      width={44}
      height={33}
      viewBox="0 0 44 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ fill: "black" }}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.56 25.471s-.988 3.415-2.308 4.779c-1.672 1.791-3.677 2.171-4.538 2.276-.282.021-.573.04-.864.058l-.002-.012a1.342 1.342 0 01-1.072-.379 1.362 1.362 0 010-1.929c.242-.242.556-.358.871-.38l-.001-.016.14-.01c.713-.086 2.265-.093 3.652-1.574 1.093-1.126 1.448-3.686 1.448-3.686s.364-3.004.364-6.008v-4.194c0-3.005-.364-6.011-.364-6.011s-.355-2.56-1.448-3.686c-1.387-1.479-2.94-1.486-3.652-1.573C30.687 2.75 22 2.752 22 2.752s-8.687-.002-13.787.374c-.712.087-2.265.094-3.65 1.573C3.47 5.826 3.113 8.385 3.113 8.385c-.001 0-.364 3.006-.364 6.01v4.195c0 3.004.364 6.008.364 6.008-.001 0 .356 2.56 1.447 3.686 1.386 1.48 3.207 1.434 4.019 1.589 2.915.284 13.419.377 13.419.377a1.375 1.375 0 110 2.75c-.023 0-.045-.004-.068-.005-.868-.01-10.838-.121-14.204-.452-.98-.189-3.306-.502-4.98-2.293C1.426 28.886.44 25.471.44 25.471S0 22.521 0 18.884v-4.786C0 10.46.44 7.51.44 7.51s.986-3.394 2.306-4.757C4.421.96 6.424.56 7.284.455 13.443 0 21.991 0 21.991 0h.018s8.549 0 14.705.455c.861.105 2.866.505 4.538 2.297 1.32 1.363 2.309 4.757 2.309 4.757S44 10.46 44 14.1v4.785c0 3.638-.44 6.587-.44 6.587zM27.5 30.25a1.375 1.375 0 110 2.75 1.375 1.375 0 010-2.75zm-6.147-7.035l-.013.007a1.33 1.33 0 01-.694.197 1.35 1.35 0 01-.624-2.548l-.002-.001 7.014-3.74c-.004-.004-.015-.007-.008-.01a.687.687 0 00.388-.62.687.687 0 00-.338-.587l-8.21-4.388a.682.682 0 00-.984.575l.001 8.512h-.01l.001.014a1.376 1.376 0 01-2.75 0v-9.577a2.799 2.799 0 014.241-2.397c.017.009.023.006.037.012l9.485 5.465c.201.117.386.256.558.427a2.75 2.75 0 010 3.89 2.827 2.827 0 01-.514.4l-7.523 4.336c-.018.012-.037.021-.055.033z"
        fill="#fff"
      ></path>
    </svg>
  );
}
