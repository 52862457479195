import { Button } from "trunx";
import { useHistory } from "react-router-dom";
import PageTemplate from "../pagetemplate/PageTemplate";
import {
  addArticle,
  getArticleById,
  updateArticle,
} from "../../services/ArticleService";
import { AppPaths } from "../../AppPaths";
import ArticlePreview from "../../components/articles/ArticlePreview";
import { useEffect, useState } from "react";
import { orderBy } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContex";

export default function CreateArticlesPage(id?: any) {
  const history = useHistory();
  const currentId = history.location.state as string;

  useEffect(() => {
    if (currentId) {
      getArticleById(currentId).then((data) => {
        setArticleTitle(data?.title);
        setArticleContent(data?.content);
        setArticleLink(data?.link);
        setArticleOrder(data?.order);
      });
    }
  }, []);

  function onSubmit() {
    if (currentId) {
      const dataToUpdate = {
        title: articleTitle ? articleTitle : "",
        content: articleContent ? articleContent : "",
        link: articleLink ? articleLink : "",
        order: articleOrder ? articleOrder : 1000,
      };
      updateArticle(currentId, dataToUpdate)
        .then(() => history.push(AppPaths.ADMIN))
        .catch((e) => console.log(e));
    } else {
      addArticle({
        file: articleImage,
        title: articleTitle ? articleTitle : "",
        content: articleContent ? articleContent : "",
        link: articleLink ? articleLink : "",
        order: articleOrder ? articleOrder : 1000,
      })
        .then(() => {
          history.push(AppPaths.ADMIN);
        })
        .catch((e) => console.log(e));
    }
  }

  const { currentUser } = useAuth();

  const [articleTitle, setArticleTitle] = useState<string>();
  const [articleContent, setArticleContent] = useState<string>();
  const [articleLink, setArticleLink] = useState<string>();
  const [articleImage, setArticleImage] = useState<any>();
  const [articleOrder, setArticleOrder] = useState<number>();

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setArticleImage(event.target.files[0]);
    }
  };

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      {currentUser != null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label htmlFor="article-title-input">Tittel</label>
            <input
              type="text"
              placeholder="tittel"
              id="article-title-input"
              onChange={(e) => setArticleTitle(e.target.value)}
              style={{ marginBottom: "1rem" }}
              value={articleTitle}
            />

            <label htmlFor="article-link-input">Lenke</label>
            <input
              type="text"
              placeholder="Lenke"
              id="article-link-input"
              onChange={(e) => setArticleLink(e.target.value)}
              style={{ marginBottom: "1rem" }}
              value={articleLink}
            />

            <label htmlFor="article-image-input">Bilde</label>
            <input
              type="file"
              placeholder="Bilde"
              id="article-image-input"
              onChange={onImageChange}
              style={{ marginBottom: "1rem" }}
              //value={articleImage}
            />

            <label htmlFor="article-order-input">Plassnummer</label>
            <input
              type="number"
              placeholder="Rekkefølge"
              id="article-order-input"
              onChange={(e) => {
                const value = Number(e.target.value);
                setArticleOrder(value);
              }}
              style={{ marginBottom: "1rem" }}
              value={articleOrder}
            />

            <label htmlFor="article-content-input">Innhold</label>
            <textarea
              placeholder="Innhold"
              id="article-contet-input"
              onChange={(e) => setArticleContent(e.target.value)}
              style={{ width: "300px", height: "200px", marginBottom: "1rem" }}
              value={articleContent}
            />

            <Button isRounded isPrimary onClick={onSubmit}>
              {currentId ? "Rediger artikkel" : "Opprett artikkel"}
            </Button>
          </div>

          <ArticlePreview
            title={articleTitle}
            content={articleContent}
            link={articleLink}
            imageUrl={articleImage ? URL.createObjectURL(articleImage) : ""}
          />
        </div>
      ) : null}
    </PageTemplate>
  );
}
