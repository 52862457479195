import "./RefrenceCard.scss";

import { Timestamp } from "firebase/firestore";
import { timestampFormater } from "../../utilities/TimestampFormater";
import { useHistory } from "react-router-dom";
import { AppPaths } from "../../AppPaths";
import { StringTruncater } from "../../utilities/StringTruncater";

export interface refrenceCardProps {
  id: string;
  logoUrl: string;
  imageUrl: string;
  content: string;
  lastEdited: Timestamp;
  title: string;
}

export default function RefrenceCard({
  id,
  imageUrl,
  content,
  logoUrl,
  lastEdited,
  title
}: refrenceCardProps) {
  const history = useHistory();
  return (
    <div
      className="refrence-card"
      onClick={() =>
        history.push(`${AppPaths.DETAILED_REFRENCE_VIEW}?id=${id}`)
      }
    >
      <h3 className="refrence-card-title">{title}</h3>
      <img src={logoUrl} className="refrence-card-logo" />
      <div className="refrence-card-image-container">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="The image of the refrence"
            className="refrence-card-image"
          />
        )}
      </div>
      <div className="refrence-card-text-content">
        <p style={{ marginBottom: "0.75rem", wordBreak: "break-word" }}>
          {StringTruncater(125, content)}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            width: "100%",
            flexGrow: 1,
          }}
        >
          {lastEdited && (
            <p className="refrence-card-date">
              {timestampFormater(lastEdited)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
