import { useHistory } from "react-router-dom";
import { Button, Modal } from "trunx";
import { AppPaths } from "../../AppPaths";

interface SuccessfulOrderModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function SuccessfulOrderModal({
  isOpen,
  setIsOpen,
}: SuccessfulOrderModalProps) {
  const history = useHistory();
  return (
    <Modal>
      <Modal.Background isFlex isAlignItemsCenter isJustifyContentCenter>
        <Modal.Content
          isFlex
          isFlexDirectionColumn
          isJustifyContentSpaceAround
          style={{
            background: "#00d1b2",
            maxWidth: "350px",
            minHeight: "200px",
            padding: "0.5rem",
          }}
        >
          <div>
            <p>Takk for din forespørsel.</p>
            <p>Vi vil ta kontakt så snart som mulig.</p>
          </div>
          <Button
            onClick={() => {
              setIsOpen(false);
              history.push(AppPaths.HOME);
            }}
          >
            Lukk
          </Button>
        </Modal.Content>
      </Modal.Background>
    </Modal>
  );
}
