import { Button, Column } from "trunx";
import HyInputField from "../components/form/HyInputField";
import { useHistory } from "react-router-dom";
import { AppPaths } from "../AppPaths";
import { useAuth } from "../contexts/AuthContex";

interface LogInProps {
  email: string;
  password: string;
}

export default function SignUpPage() {
  const history = useHistory();
  const { login } = useAuth();

  const onSubmit = () => {
    const email: string = (
      document.getElementById("login-email") as HTMLInputElement
    ).value;
    const pw: string = (document.getElementById("login-pw") as HTMLInputElement)
      .value;
    login(email, pw).then(() => {
      history.push(AppPaths.HOME);
    });
  };

  return (
    <Column isFlex isFlexDirectionRow isJustifyContentCenter>
      <form onSubmit={onSubmit}>
        <Column
          isFlex
          isFlexDirectionColumn
          isJustifyContentCenter
          isAlignItemsStretch
        >
          <HyInputField id="login-email" type="text" />
          <HyInputField id="login-pw" type="password" />
          <Button onClick={(e) => onSubmit}>Login</Button>
        </Column>
      </form>
    </Column>
  );
}
