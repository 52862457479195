import { useEffect, useState } from "react";
import { refrenceCardProps } from "../../components/refrences/RefrenceCard";
import { getReferenceById } from "../../services/RefrenceService";
import { timestampFormater } from "../../utilities/TimestampFormater";
import PageTemplate from "../pagetemplate/PageTemplate";
import "./DetailedRefrenceView.scss";

export default function DetailedRefrenceView() {
  const [refrence, setRefrence] = useState<refrenceCardProps>();

  useEffect(() => {
    const refrenceId = window.location.hash.split("=")[1];
    if (refrenceId) {
      getReferenceById(refrenceId).then((refrence) => {
        if (refrence) {
          setRefrence(refrence as refrenceCardProps);
        }
      });
    }
  }, []);

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      {refrence && (
        <div className="detailed-view-refrence">
          <h1>{refrence.title}</h1>
          {refrence.logoUrl && <img src={refrence.logoUrl} alt="reference logo" />}
          <img src={refrence.imageUrl} alt="refrence image" />
          <div className="detailed-view-refrence-content">
            <p>{refrence.content}</p>
            <p>{timestampFormater(refrence.lastEdited)}</p>
          </div>
        </div>
      )}
    </PageTemplate>
  );
}
