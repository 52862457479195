import { useEffect, useState } from "react";
import { ArticleCardProps } from "../../components/articles/ArticleCard";
import { getArticleById } from "../../services/ArticleService";
import { timestampFormater } from "../../utilities/TimestampFormater";
import PageTemplate from "../pagetemplate/PageTemplate";
import "./DetailedArticleView.scss";

export default function DetailedArticleView() {
  const [article, setArticle] = useState<ArticleCardProps>();

  useEffect(() => {
    const articleId = window.location.hash.split("=")[1];
    if (articleId) {
      getArticleById(articleId).then((article) => {
        if (article) {
          setArticle(article as ArticleCardProps);
        }
      });
    }
  }, []);

  return (
    <PageTemplate isNavbarTransparentToSolid={false}>
      {article && (
        <div className="detailed-view-article">
          <h1>{article.title}</h1>
          <div className="detailed-view-article-container">
            {article.imageUrl && (
              <img src={article.imageUrl} alt="article image" />
            )}
            <div className="detailed-view-article-content">
              <p>{article.content}</p>
              <p>
                <a href={article.link}>{article.link}</a>
              </p>
              <p>{timestampFormater(article.lastEdited)}</p>
            </div>
          </div>
        </div>
      )}
    </PageTemplate>
  );
}
