import { Timestamp } from "firebase/firestore";
import ArticleCard, { ArticleCardProps } from "./ArticleCard";

export default function ArticlePreview({
  title,
  content,
  imageUrl,
  link,
}: {
  title?: string;
  content?: string;
  imageUrl?: string;
  link?: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Forhåndsvisning</h1>
      <ArticleCard
        id={""}
        title={title ? title : ""}
        imageUrl={imageUrl ? imageUrl : ""}
        content={content ? content : ""}
        lastEdited={Timestamp.now()}
        link={link}
      />
    </div>
  );
}
