import "./ProductCard.scss";

interface ProductCardProps {
  title: string;
  text: string;
  imageSrc: string;
  id?: string;
}

export default function ProductCard({
  title,
  text,
  imageSrc,
  id,
}: ProductCardProps) {
  return (
    <div className="product-card" id={id}>
      <h2>{title}</h2>
      <img src={imageSrc} alt="" />
      <p>{text}</p>
    </div>
  );
}
