import "./AdminTable.scss";

import { useEffect, useState } from "react";
import { deleteArticle, getAllArticles } from "../../services/ArticleService";
import {
  getAllActiveQuotes,
  getAllQuotes,
  markQuoteAsDone,
} from "../../services/QuoteService";
import {
  deleteRefrence,
  getAllRefrences,
} from "../../services/RefrenceService";
import {
  getAllActiveDemoOrders,
  markDemoOrderAsDone,
} from "../../services/OrderDemoService";
import { Timestamp } from "firebase/firestore";
import { AppPaths } from "../../AppPaths";
import { useHistory } from "react-router-dom";

export enum AdminTableOptions {
  ARTICLES = "articles",
  REFRENCES = "references",
  ORDERS = "orders",
  DEMO_ORDERS = "demo-orders",
}

export default function AdminTable({ typeOfTable }: { typeOfTable: string }) {
  const [data, setData] = useState<any[]>([]);
  const history = useHistory();

  useEffect(() => {
    const tmpData: any[] = [];
    let promise: any;
    switch (typeOfTable) {
      case AdminTableOptions.ARTICLES:
        getAllArticles().then((snapshot: { docs: any[] }) => {
          snapshot.docs.forEach((doc) => {
            tmpData.push(doc.data());
          });
          setData(tmpData);
        });
        break;
      case AdminTableOptions.REFRENCES:
        getAllRefrences().then((snapshot: { docs: any[] }) => {
          snapshot.docs.forEach((doc) => {
            tmpData.push(doc.data());
          });
          setData(tmpData);
        });
        break;
      case AdminTableOptions.ORDERS:
        getAllActiveQuotes().then((snapshot: { docs: any[] }) => {
          snapshot.docs.forEach((doc) => {
            tmpData.push(doc.data());
          });
          console.log(tmpData);
          setData(tmpData);
        });
        break;
      case AdminTableOptions.DEMO_ORDERS:
        getAllActiveDemoOrders().then((snapshot: { docs: any[] }) => {
          snapshot.docs.forEach((doc) => {
            tmpData.push(doc.data());
          });
          setData(tmpData);
        });
        break;
    }
  }, [typeOfTable]);

  function renderTableRow(data: any) {
    switch (typeOfTable) {
      case AdminTableOptions.ARTICLES:
        return (
          <AdminTableRow
            id={data.id}
            title={data.title}
            onDelete={() => deleteArticle(data.id)}
            onEdit={() =>
              history.push({
                pathname: AppPaths.CREATE_ARTICLES,
                state: data.id,
              })
            }
            typeOfTable={AdminTableOptions.ARTICLES}
          />
        );
      case AdminTableOptions.REFRENCES:
        return (
          <AdminTableRow
            id={data.id}
            title={data.title}
            onDelete={() => deleteRefrence(data.id)}
            onEdit={() =>
              history.push({
                pathname: AppPaths.CREATE_REFRENCE,
                state: data.id,
              })
            }
            typeOfTable={AdminTableOptions.REFRENCES}
          />
        );
      case AdminTableOptions.ORDERS:
        return (
          <AdminTableRow
            id={data.id}
            orgnr={data.orgnr}
            title={data.company}
            onDelete={() => markQuoteAsDone(data.id)}
            email={data.email}
            phoneNr={data.phoneNumber}
            nrOf10002={data.nrOf10002}
            nrOf10003={data.nrOf10003}
            nrOf30001={data.nrOf30001}
            nrOf30002={data.nrOf30002}
            nrOf30003={data.nrOf30003}
            nrOf40001={data.nrOf40001}
            nrOf40002={data.nrOf40002}
            nrOf50001={data.nrOf50001}
            code={data.code}
            company={data.company}
            typeOfTable={AdminTableOptions.ORDERS}
            created={data.created}
          />
        );
      case AdminTableOptions.DEMO_ORDERS:
        return (
          <AdminTableRow
            id={data.id}
            title={data.company}
            onDelete={() => markDemoOrderAsDone(data.id)}
            email={data.email}
            phoneNr={data.phoneNumber}
            typeOfTable={AdminTableOptions.DEMO_ORDERS}
          />
        );
    }
  }

  return (
    <table className="admin-table">
      <th>Tittel</th>
      {typeOfTable == AdminTableOptions.ORDERS ? <th>Orgnr</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <th>E-post</th>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <th>Tlf</th>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <th>Tidspunkt</th>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>10002</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>10003</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>30001</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>30002</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>30003</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>40001</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>40002</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>50001</th> : null}
      {typeOfTable == AdminTableOptions.ORDERS ? <th>Kode</th> : null}
      <th>Slett</th>
      {typeOfTable == AdminTableOptions.ARTICLES ||
      typeOfTable == AdminTableOptions.REFRENCES ? (
        <th>Rediger</th>
      ) : null}
      {data &&
        data.map((data) => {
          return renderTableRow(data);
        })}
    </table>
  );
}

function AdminTableRow({
  phoneNr,
  email,
  nrOf10002,
  nrOf10003,
  nrOf30001,
  nrOf30002,
  nrOf30003,
  nrOf40001,
  nrOf40002,
  nrOf50001,
  code,
  id,
  company,
  title,
  onDelete,
  typeOfTable,
  orgnr,
  created,
  onEdit,
}: {
  id: string;
  title: string;
  onDelete: (id: string) => void;
  phoneNr?: string;
  email?: string;
  nrOf10002?: number;
  nrOf10003?: number;
  nrOf30001?: number;
  nrOf30002?: number;
  nrOf30003?: number;
  nrOf40001?: number;
  nrOf40002?: number;
  nrOf50001?: number;
  company?: string;
  code?: string;
  orgnr?: number;
  typeOfTable?: AdminTableOptions;
  created?: Timestamp;
  onEdit?: (id: string) => void;
}) {
  const createdAsString = created?.toDate().toLocaleDateString();

  return (
    <tr className="admin-table-row">
      <td className="admin-table-row-entry">{title}</td>
      {typeOfTable == AdminTableOptions.ORDERS ? <td>{orgnr}</td> : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <td className="admin-table-row-entry">{email}</td>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <td className="admin-table-row-entry">{phoneNr}</td>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <td className="admin-table-row-entry">{orgnr}</td>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <td className="admin-table-row-entry">{company}</td>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS ||
      typeOfTable == AdminTableOptions.DEMO_ORDERS ? (
        <td className="admin-table-row-entry">
          {created ? createdAsString : null}
        </td>
      ) : null}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf10002}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf10003}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf30001}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf30002}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf30003}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf40001}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf40002}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS && (
        <td className="admin-table-row-entry">{nrOf50001}</td>
      )}
      {typeOfTable == AdminTableOptions.ORDERS ? <td>{code}</td> : null}
      <td>
        <button onClick={() => onDelete(id)}>Slett</button>
      </td>
      {onEdit &&
      (typeOfTable == AdminTableOptions.ARTICLES ||
        typeOfTable == AdminTableOptions.REFRENCES) ? (
        <td>
          <button onClick={() => onEdit(id)}>Rediger</button>
        </td>
      ) : null}
    </tr>
  );
}
