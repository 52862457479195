import "./ArticleCard.scss";

import { Timestamp } from "firebase/firestore";
import { timestampFormater } from "../../utilities/TimestampFormater";
import { AppPaths } from "../../AppPaths";
import { useHistory } from "react-router-dom";
import { StringTruncater } from "../../utilities/StringTruncater";

export interface ArticleCardProps {
  id: string;
  title: string;
  imageUrl: string;
  content: string;
  link?: string;
  lastEdited: Timestamp;
}

export default function ArticleCard({
  id,
  title,
  imageUrl,
  content,
  link,
  lastEdited,
}: ArticleCardProps) {
  const history = useHistory();
  return (
    <div
      className="article-card"
      onClick={() => history.push(`${AppPaths.DETAILED_ARTICLE_VIEW}?id=${id}`)}
    >
      <h6 className="article-card-title">{title}</h6>
      <div className="article-card-image-container">
        {imageUrl && (
          <img
            src={imageUrl}
            alt="The image of the article"
            className="article-card-image"
          />
        )}
      </div>
      <div className="article-card-text-content">
        <p style={{ marginBottom: "0.75rem" }}>
          {StringTruncater(125, content)}
        </p>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: "break-all" }}
          >
            {link}
          </a>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            width: "100%",
            flexGrow: 1,
          }}
        >
          {lastEdited && (
            <p className="article-card-date">{timestampFormater(lastEdited)}</p>
          )}
        </div>
      </div>
    </div>
  );
}
